import styled, { css } from "styled-components";
import IntoBG from "assets/img/IntoBG.jpg";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { MEDIA } from "constants/media";

const Intro = styled.section`
  padding: 415px 0 105px;
  text-align: center;
  background-image: url(${IntoBG});
  background-size: cover;
  margin-bottom: 40px;
  background-position: center center;
  ${MEDIA.xs} {
    padding: 200px 0 100px;
  }
`;

const IntroContainer = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
`;

const IntoCapture = styled.div`
  color: #ffffff;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: 500;
  ${MEDIA.xs} {
    margin-bottom: 20px;
  }
`;
const IntroTitle = styled.div`
  font-size: 56px;
  font-weight: bold;
  max-width: 900px;
  margin: 0 auto;
  color: #ffffff;
  ${MEDIA.xs} {
    font-size: 32px;
    background-size: contain;
  }
`;

const Mission = styled.section``;

const MissionContainer = styled.div`
  width: 1360px;
  background: #f6f6f6;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
  ${MEDIA.xs} {
    max-width: 100%;
    padding: 0 15px;
  }
`;

const MissionImg = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: 75px;
  ${MEDIA.lgAndLess} {
    max-width: 35%;
    margin: 0;
  }
  ${MEDIA.xs} {
    display: none;
  }
`;

const MissionText = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 55%;
  ${MEDIA.lgAndLess} {
    padding: 35px 15px;
  }
  ${MEDIA.xs} {
    width: 100%;
  }
`;

const MissionCapture = styled.div`
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #7a00e6;
  font-weight: 500;
  margin-bottom: 40px;
  ${MEDIA.xs} {
    margin-bottom: 20px;
  }
`;

const MissionDesc = styled.div`
  font-size: 20px;
  color: #000000;
`;

const Courses = styled.section`
  padding: 65px 0 16px;
`;

const CoursesContainer = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
`;

const CoursesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;
`;

const CoursesTitle = styled.div`
  width: fit-content;
  font-weight: bold;
  font-size: 32px;
`;

const CoursesList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 14px;
  ${MEDIA.mdAndLess} {
    flex-direction: column;
    justify-content: center;
  }
`;

const CoursesItem = styled.div`
  flex: 0 0 calc(33% - 14px);
  ${MEDIA.mdAndLess} {
    flex: 0 0 100%;
  }
`;

const CourseCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background: #f4f2f6;
  ${MEDIA.mdAndLess} {
    max-width: 500px;
    margin: 0 auto 50px;
  }
  ${({ isInProgress }) =>
    isInProgress &&
    css`
      border: 1px solid #16a513;
      box-shadow: 0px 8px 16px rgba(222, 234, 223, 0.7);
    `}
`;

const CourseCardHeader = styled.div`
  padding: 16px 25px 0 33px;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

const CourseHeaderTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

const CourseHeaderModules = styled.div`
  font-size: 14px;
  padding: 8px 24px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  color: #ffffff;
  margin-bottom: 40px;
`;

const CourseHeaderLogo = styled.div`
  width: 115px;
  height: 115px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  overflow: hidden;
  transform: translateY(20px);
  background: #fff;
  opacity: 1;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const CourseCardContent = styled.div`
  padding: 42px 0 26px;
`;

const CourseCardInfo = styled.div`
  padding: 0 33px;
  ${MEDIA.lgAndLess} {
    padding: 0 15px;
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

const CourseCardNumber = styled.div`
  color: #7a00e6;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
`;

const CountDownItem = styled.div`
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const CountDownRemainingText = styled.div`
  color: ${({isExpired}) => isExpired ? '#c05056' : '#7a00e6'};
  font-weight: 900;
  font-size: 20px;
  padding-right: 16px;
`;

const CountDownNumber = styled.div`
  color: ${({isExpired}) => isExpired ? '#c05056' : '#7a00e6'};
  font-weight: 900;
  font-size: 20px;
`;

const CountDownText = styled.div`
  color: ${({isExpired}) => isExpired ? '#c05056' : '#000000'};
`;

const CountDownHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CourseCardTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
  ${MEDIA.lgAndLess} {
    font-size: 18px;
    min-height: 50px;
    overflow: hidden;
    margin-bottom: 30px;
  }
`;

const CourseCardDesc = styled.div`
  margin-bottom: 24px;
  min-height: 90px;
`;

const CourseCardStatus = styled.div`
  //min-height: 95px;
  height: 40px;
`;

const CourseCardStatusFinished = styled.div`
  background: rgba(22, 165, 19, 0.1);
  border-radius: 16px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CourseCardFinishIcon = styled(CheckCircleTwoTone)`
  color: #16a513;
  font-size: 25px;
  margin-right: 10px;
  vertical-align: middle;
`;

const CourseCardFinish = styled.div`
  color: #16a513;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
`;

const CourseCardGrade = styled.div`
  color: #16a513;
`;

const StatusProgress = styled.div`
  background: #deeadf;
  border-radius: 16px;
  height: 8px;
  position: relative;
`;

const StatusProgressInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #16a513;
  right: ${({ progress }) => 100 - progress}%;
  border-radius: 16px;
`;

const StatusProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

const CourseCardFooter = styled.div`
  height: 81px;
  padding: 0 70px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e4e4e4;
  justify-content: center;
  ${MEDIA.lgAndLess} {
    padding: 15px;
    height: 95px;
  }
`;

const ExploreMore = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  svg {
    transform: rotate(180deg);
    width: 14px;
    height: 7px;
    margin-left: 20px;
    vertical-align: middle;
    path {
      stroke: #7a00e6;
      transition: stroke 0.5s;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      path {
        stroke: #22004b;
      }
    }
  }
`;

const CourseCardBlockedLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CourseCardBlockedLinkIcon = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  padding-top: 10px;
  text-align: center;
  margin-right: 16px;
  flex: 0 0 40px;
  .anticon {
    font-size: 16px;
    color: #7a00e6;
  }
`;

const CourseCardBlockedText = styled.div``;

const FinalAssessment = styled.section``;

const FinalAssessmentContainer = styled.div`
  max-width: 1360px;
  margin: 55px auto 65px;
  background: #f4f2f6;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
`;

const FinalAssessmentImg = styled.img`
  display: inline-block;
  width: 195px;
  height: 195px;
  ${MEDIA.mdAndLess} {
    display: none;
  }
`;
const ExpiredImg = styled.img`
  display: inline-block;
  width: 195px;
  height: 195px;
  padding: 20px;
  ${MEDIA.mdAndLess} {
    display: none;
  }
`;
const ExpiredCapture = styled.div`
  font-size: 14px;
  color: #c05056;
  margin-bottom: 6px;
  text-transform: uppercase;
  font-weight: 500;
`;
const ExpiredText = styled.div`
  max-width: 520px;
  ${MEDIA.lgAndLess} {
    padding: 15px;
  }
  ${MEDIA.xs} {
    max-width: 100%;
  }
`;

const ExpiredContainer = styled.div`
  max-width: 1360px;
  margin: 16px auto 65px;
  background: #f4f2f6;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
`;

const FinalAssessmentInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  justify-content: space-between;
  align-items: center;
  flex: auto;

  ${MEDIA.lgAndLess} {
    padding: 0 25px;
  }
  ${MEDIA.xs} {
    flex-direction: column;
    padding: 0 25px 25px;
  }
`;

const FinalAssessmentText = styled.div`
  max-width: 520px;
  ${MEDIA.lgAndLess} {
    max-width: 45%;
    padding: 15px;
  }
  ${MEDIA.xs} {
    max-width: 100%;
  }
`;

const FinalAssessmentCapture = styled.div`
  font-size: 14px;
  color: #7a00e6;
  margin-bottom: 6px;
  text-transform: uppercase;
  font-weight: 500;
`;

const FinalAssessmentTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 6px;
  ${MEDIA.lgAndLess} {
    font-size: 20px;
  }
`;

const FinalAssessmentTitleBlocked = styled.div`
  padding: 18px 32px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${MEDIA.lgAndLess} {
    padding: 10px;
  }
  ${MEDIA.xs} {
    width: 100%;
    margin: 15px 0;
  }
`;

const FinalAssessmentTitleBlockedIcon = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  padding-top: 10px;
  text-align: center;
  margin-right: 16px;
  flex: 0 0 40px;
  .anticon {
    font-size: 16px;
    color: #7a00e6;
  }
`;

const Sponsor = styled.section``;

const SponsorContainer = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  padding: 40px;
  background: #22004b;
  display: flex;
  flex-direction: row;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
  ${MEDIA.xs} {
    max-width: 100%;
    padding: 25px;
  }
`;

const SponsorImg = styled.div`
  width: 550px;
  height: 650px;
  overflow: hidden;
  ${MEDIA.lgAndLess} {
    width: 50%;
    height: auto;
  }
  ${MEDIA.mdAndLess} {
    display: none;
  }
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const SponsorContent = styled.div`
  display: flex;
  justify-content: center;
  flex: auto;
  align-items: center;
`;

const SponsorContentWrapper = styled.div`
  max-width: 550px;
  ${MEDIA.lgAndLess} {
    padding: 25px;
  }
  ${MEDIA.xs} {
    padding: 25px 0;
  }
`;

const SponsorLogo = styled.div`
  margin-bottom: 40px;
`;

const SponsorCapture = styled.div`
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 8px;
  font-weight: 500;
`;

const SponsorTitle = styled.div`
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  font-size: 35px;
  ${MEDIA.xs} {
    margin-bottom: 20px;
    font-size: 32px;
  }
`;

const SponsorDesc = styled.div`
  font-size: 18px;
  color: white;
  margin-bottom: 40px;
`;

const SponsorButton = styled.a`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  padding: 17px 30px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  svg {
    transform: rotate(180deg);
    width: 14px;
    height: 7px;
    margin-left: 20px;
    vertical-align: middle;
    path {
      stroke: #ffffff;
    }
  }
  &:hover {
    color: #ffffff;
    opacity: 0.8;
    text-decoration: none;
  }
`;

const MissionVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export default {
  Intro,
  CoursesContainer,
  IntroContainer,
  IntoCapture,
  IntroTitle,
  Mission,
  MissionImg,
  MissionText,
  MissionCapture,
  MissionDesc,
  MissionContainer,
  MissionVideo,
  Courses,
  CoursesTitle,
  CoursesHeader,
  CountDownRemainingText,
  CountDownItem,
  CountDownNumber,
  CountDownText,
  CountDownHolder,
  CoursesList,
  CoursesItem,
  CourseCard,
  CourseCardHeader,
  CourseHeaderTopRow,
  CourseHeaderModules,
  CourseHeaderLogo,
  CourseCardContent,
  CourseCardInfo,
  CourseCardNumber,
  CourseCardTitle,
  CourseCardDesc,
  CourseCardStatus,
  CourseCardStatusFinished,
  CourseCardFinishIcon,
  CourseCardFinish,
  CourseCardGrade,
  StatusProgress,
  StatusProgressInner,
  StatusProgressInfo,
  CourseCardFooter,
  ExploreMore,
  CourseCardBlockedLinkIcon,
  CourseCardBlockedLink,
  CourseCardBlockedText,
  FinalAssessment,
  FinalAssessmentContainer,
  FinalAssessmentImg,
  FinalAssessmentInfo,
  FinalAssessmentCapture,
  FinalAssessmentText,
  FinalAssessmentTitle,
  FinalAssessmentTitleBlocked,
  FinalAssessmentTitleBlockedIcon,
  ExpiredImg,
  ExpiredCapture,
  ExpiredText,
  ExpiredContainer,
  Sponsor,
  SponsorContainer,
  SponsorImg,
  SponsorContent,
  SponsorLogo,
  SponsorCapture,
  SponsorTitle,
  SponsorDesc,
  SponsorContentWrapper,
  SponsorButton,
};
