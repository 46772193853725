import React from "react";

import Styled from "./styled";

const AssessmentMcq = ({ question, isPreAssessment, result, answers, setAnswers }) => {
  const getStatus = (questionID, id, answersDetails, isPreAssessment) => {
    if (!answersDetails) return null;
    if(isPreAssessment) return "hasResult"
    if (
      answersDetails?.isCorrect &&
      `${answersDetails?.correctOptionID}` === `${id}`
    ) {
      return "correctAnswer";
    }
    if (
      !answersDetails?.isCorrect &&
      result?.passed &&
      `${id}` === `${answersDetails?.correctOptionID}`
    )
      return "correctOption";
    if (
      !answersDetails[questionID]?.isCorrect &&
      `${answers[questionID]}` === `${id}`
    )
      return "incorrectAnswer";

    return "hasResult";
  };
  return (
    <>
      {question?.options?.map(({ option, id }, index) => (
        <Styled.Option
          status={getStatus(
            question?.id,
            `${id}`,
            result?.answersDetails?.[question?.id],
            isPreAssessment
          )}
          onClick={() =>
            setAnswers({
              ...answers,
              [question?.id]: `${id}`,
            })
          }
          selected={answers[question?.id] === `${id}`}
          key={id}
        >
          <Styled.CheckBox
            status={getStatus(
              question?.id,
              `${id}`,
              result?.answersDetails?.[question?.id],
              isPreAssessment
            )}
            selected={answers[question?.id] === `${id}`}
          />
          <div>
            <Styled.OptionIndex>
              {(parseInt(index) + 10).toString(36).toUpperCase()}
              {". "}
            </Styled.OptionIndex>
            {option}
          </div>
        </Styled.Option>
      ))}
    </>
  );
};

export default AssessmentMcq;
