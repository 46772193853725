import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import * as progressApi from "./progress.api";
import * as progressActions from "./progress.actions";
import * as progressSelector from "./progress.selector";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";

function* getProgressDataOnLocationChangeData() {
  try {
    const progress = yield select(progressSelector.progressSelector);
    const storage = localStorage.getItem("storageTyp");
    const token = window[storage]?.jwt;
    if (!progress && token) {
      yield put({ type: progressActions.GET_PROGRESS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchProgress() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(progressApi.getProgressRequest);

    yield put({
      type: progressActions.GET_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: progressActions.GET_PROGRESS_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateProgress({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(progressApi.updateProgressRequest, payload);

    yield put({
      type: progressActions.UPDATE_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: progressActions.UPDATE_PROGRESS_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* progressSaga() {
  yield takeEvery(LOCATION_CHANGE, getProgressDataOnLocationChangeData);
  yield takeEvery(progressActions.GET_PROGRESS, fetchProgress);
  yield takeEvery(progressActions.UPDATE_PROGRESS, updateProgress);
}
