import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ArrowIcon from "assets/img/arrow.svg";
import Styled from "./styled";

import { updateProgressAction } from "services/progress/progress.actions";
import { progressSelector } from "services/progress/progress.selector";
import { slidesLogsSelector } from "services/logs/logs.selectors";
import { updateSlideLogsAction } from "services/logs/logs.actions";

const LessonSlides = ({ lesson }) => {
  const slides = lesson?.material?.[0]?.slides;
  const [slideIndex, setSlideIndex] = useState(0);
  const [initialSlideIndex, seInitialSlideIndex] = useState(0);
  const progress = useSelector(progressSelector);

  const { courseId } = useParams();

  const currentProgress = progress?.[courseId];
  const slidesLog = useSelector(slidesLogsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    setSlideIndex(0);
  }, [lesson?.id]);

  useEffect(() => {
    if (
      !initialSlideIndex &&
      !currentProgress?.finishedLessons?.includes(lesson?.id)
    ) {
      seInitialSlideIndex(slidesLog?.slides?.length || 0);
      setSlideIndex(slidesLog?.slides?.length || 0);
    }
  }, [slidesLog?.slides]);

  const onArrowClick = (index) => {
    setSlideIndex(index);
    if (!currentProgress?.finishedLessons?.includes(lesson?.id)) {
      dispatch(
        updateSlideLogsAction({
          lesson: lesson?.id,
          slide: slides?.[index]?.id,
        })
      );
    }
    if (
      index + 1 === slides.length &&
      !currentProgress?.finishedLessons?.includes(lesson?.id)
    ) {
      dispatch(
        updateProgressAction({
          lesson: lesson?.id,
          course: courseId,
        })
      );
    }
  };

  return (
    <Styled.SliderWrapper>
      {!!slideIndex && (
        <Styled.ArrowPrev onClick={() => onArrowClick(slideIndex - 1)}>
          <img src={ArrowIcon} alt="" />
        </Styled.ArrowPrev>
      )}
      {slideIndex + 1 !== slides?.length && (
        <Styled.ArrowNext onClick={() => onArrowClick(slideIndex + 1)}>
          <img src={ArrowIcon} alt="" />
        </Styled.ArrowNext>
      )}
      <Styled.Status>
        Slide {slideIndex + 1} / {slides?.length}
      </Styled.Status>
      <Carousel
        showStatus={false}
        showArrows={false}
        selectedItem={slideIndex}
        showIndicators={false}
        onChange={setSlideIndex}
      >
        {slides?.map((slide) => (
          <div key={slide?.id}>
            <img src={slide?.image?.url} alt="" />
            {slide?.description && (
              <Styled.Note>
                <Styled.NoteTitle>Note:</Styled.NoteTitle>
                <span>{slides?.[slideIndex]?.description}</span>
              </Styled.Note>
            )}
          </div>
        ))}
      </Carousel>
    </Styled.SliderWrapper>
  );
};

export default LessonSlides;
