import { createAction } from "redux-actions";

export const GET_FINAL_ASSESSMENT = "GET_FINAL_ASSESSMENT";
export const GET_FINAL_ASSESSMENT_SUCCESS = "GET_FINAL_ASSESSMENT_SUCCESS";
export const GET_FINAL_ASSESSMENT_FAILURE = "GET_FINAL_ASSESSMENT_FAILURE";

export const SUBMIT_FINAL_ASSESSMENT = "SUBMIT_FINAL_ASSESSMENT";
export const SUBMIT_FINAL_ASSESSMENT_SUCCESS =
  "SUBMIT_FINAL_ASSESSMENT_SUCCESS";
export const SUBMIT_FINAL_ASSESSMENT_FAILURE =
  "SUBMIT_FINAL_ASSESSMENT_FAILURE";

export const CLEAR_FINAL_ASSESSMENT = "CLEAR_FINAL_ASSESSMENT";

export const clearFinalAssessmentAction = createAction(CLEAR_FINAL_ASSESSMENT);
export const submitFinalAssessmentAction = createAction(
  SUBMIT_FINAL_ASSESSMENT
);
