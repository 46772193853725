import React from "react";
import StyledSelect from "./styled";

const Select = ({ children, input, ...props }) => {
  const { onBlur, value, ...resInput } = input;

  return (
    <StyledSelect value={value ? value : undefined} {...props} {...resInput}>
      {children}
    </StyledSelect>
  );
};

export default Select;
