import styled from "styled-components";
import CourseBG from "assets/img/CourseBG.jpg";
import { Progress } from "antd";
import { MEDIA } from "constants/media";

const Header = styled.div`
  background-color: #f4f2f6;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 50px;
  ${MEDIA.smAndLess} {
    text-align: center;
  }
`;

const HeaderTop = styled.div`
  background-image: url(${CourseBG});
  background-size: cover;
  padding-left: 40px;
  ${MEDIA.smAndLess} {
    padding-left: 0;
  }
`;

const HeaderLogo = styled.div`
  display: inline-block;
  width: 140px;
  height: 140px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  overflow: hidden;
  transform: translateY(40px);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const HeaderInfo = styled.div`
  padding: 50px 50px 40px;
  ${MEDIA.smAndLess} {
    padding: 15px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${MEDIA.smAndLess} {
    padding-top: 40px;
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const HeaderCapture = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  text-transform: uppercase;
`;

const HeaderTitle = styled.div`
  font-size: 40px;
  margin-bottom: 16px;
  font-weight: bold;
  ${MEDIA.mdAndLess} {
    font-size: 32px;
    margin-bottom: 20px;
  }
`;

const HeaderContent = styled.div`
  max-width: 950px;
  padding-top: 30px;
`;

const HeaderProgressBlock = styled.div``;

const HeaderProgressCircle = styled(Progress)``;

const HeaderProgressPercentage = styled.div`
  color: #16a513;
  font-size: 32px;
`;

const HeaderProgressName = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
`;

export default {
  Header,
  HeaderTop,
  HeaderLogo,
  HeaderInfo,
  HeaderWrapper,
  HeaderCapture,
  HeaderTitle,
  HeaderContent,
  HeaderProgressBlock,
  HeaderProgressCircle,
  HeaderProgressPercentage,
  HeaderProgressName,
};
