import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import Styled from "./styled";
import InputField from "components/InputField/InputField";
import { loginAction } from "services/login/login.action";
import EnvelopeIcon from "assets/img/envelopeIcon.svg";
import PasswordShow from "assets/img/PasswordShow.svg";
import LockIcon from "assets/img/Lock.svg";
import { ReactComponent as ArrowIcon } from "assets/img/arrow.svg";
import Checkbox from "../../components/Checkbox/Checkbox";

const LoginForm = ({ handleSubmit }) => {
  const [isPasswordShow, setInputType] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <Styled.InputWrap>
        <Field
          prefix={<img src={EnvelopeIcon} alt="" />}
          component={InputField}
          name="identifier"
          label="email"
          type="email"
        />
      </Styled.InputWrap>
      <Styled.InputWrap>
        <Field
          prefix={<img src={LockIcon} alt="" />}
          suffix={
            <img
              src={PasswordShow}
              alt=""
              onMouseDown={() => setInputType(true)}
              onMouseUp={() => setInputType(false)}
            />
          }
          component={InputField}
          name="password"
          label="password"
          type={isPasswordShow ? "text" : "password"}
        />
      </Styled.InputWrap>
      <Styled.RememberMeWrapper>
        <Field component={Checkbox} name={"rememberMe"}>
          Remember Me
        </Field>
      </Styled.RememberMeWrapper>
      <Styled.LoginButton htmlType="submit">
        Login
        <ArrowIcon />
      </Styled.LoginButton>
    </form>
  );
};

export default reduxForm({
  form: "loginForm",
  onSubmit: (value, dispatch) => dispatch(loginAction(value)),
})(LoginForm);
