import styled, { css } from "styled-components";
import { MEDIA } from "constants/media";
import { Link } from "react-router-dom";

const OPTION_STATUS = {
  hasResult: css`
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  `,
  correctAnswer: css`
    background: #f9f7fb;
    border: 1px solid #16a513;
    color: #000000;
    pointer-events: none;
  `,
  correctOption: css`
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid #16a51380;
    pointer-events: none;
  `,
  incorrectAnswer: css`
    background: #fff7f7;
    border: 1px solid #ff8989;
    pointer-events: none;
    text-decoration-line: line-through;
  `,
};

const CHECKBOX_STATUS = {
  incorrectAnswer: css`
    border-color: #ff8989;
    &:after {
      background-color: #ff8989;
    }
  `,
  correctOption: css`
    border-color: #16a51380;
    &:after {
      display: none;
    }
  `,
  correctAnswer: css`
    border-color: #16a513;
    &:after {
      background-color: #16a513;
    }
  `,
};

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 65px;
  font-size: 32px;
  ${MEDIA.mdAndLess} {
    margin-bottom: 32px;
  }
`;

const Question = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;
`;

const Option = styled.div`
  display: flex;
  flex: 0 0 calc(50% - 12px);
  padding: 15px;
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  ${MEDIA.mdAndLess} {
    flex: 0 0 100%;
  }
  ${({ selected }) =>
    selected &&
    css`
      border-color: #7a00e6;
      background: #f9f7fb;
    `}
  ${({ status }) => {
    return !!status && OPTION_STATUS[status];
  }}
`;

const OptionIndex = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const CheckBox = styled.div`
  position: relative;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  margin-right: 8px;
  margin-top: -2px;
  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid #7a00e6;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        background: #7a00e6;
        border-radius: 50%;
      }
    `}
  ${({ status }) => {
    return !!status && CHECKBOX_STATUS?.[status];
  }}
`;

const Button = styled.div`
  padding: 17px 32px;
  background: #7a00e6;
  border: 1px solid #7a00e6;
  border-radius: 40px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  &:hover {
    background: #ffffff;
    color: #7a00e6;
  }
`;

const ContentWrapper = styled.div`
  ${MEDIA.smAndLess} {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    border-radius: 20px 20px 0 0;
`;

const Top = styled.div`
  text-align: right;
  display: none;
  ${MEDIA.smAndLess} {
    display: block;
    padding: 20px 20px 10px;
  }
`;

const Content = styled.div`
  ${MEDIA.smAndLess} {
    width: 100%;
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
`;

const Close = styled(Link)`
  margin-bottom: 20px;
  .anticon {
    font-size: 25px;
    color: black;
  }
`;

const Scroll = styled.div`
  ${MEDIA.smAndLess} {
    overflow-y: auto;
    height: calc(100% - 50px);
    padding: 20px;
  }
`;

const List = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  &:last-child {
    border: none;
  }
`;

const CrossOption = styled.div`
  flex: 0 0 100px;
  ${MEDIA.mdAndLess} {
    flex: 0 0 100px;
    width: 100%;
    margin-bottom: 12px;
  }
  ${({ $isCorrect }) =>
    $isCorrect &&
    css`
      &&& .ant-select-selector {
        background: #e5f2e5;
        border: 1px solid #16a513 !important;
      }
    `}
  ${({ $isIncorrect }) =>
    $isIncorrect &&
    css`
      &&& .ant-select-selector {
        background: #f4f2f6;
        text-decoration: line-through;
        border: 1px solid #ff8989 !important;
      }
    `}
`;

const Cross = styled.div`
  gap: 24px;
  display: flex;
  align-items: start;
  margin-bottom: 24px;
`;

const CrossOptionText = styled.div`
  display: flex;
  ${MEDIA.mdAndLess} {
    margin-bottom: 12px;
  }
`;

const Crosses = styled.div`
  flex: 0 0 calc(50% - 24px);
  ${MEDIA.lgAndLess} {
    flex: 0 0 100%;
    order: 2;
  }
`;

const History = styled.div`
  flex: 0 0 calc(50% - 24px);
  ${MEDIA.lgAndLess} {
    flex: 0 0 100%;
    order: 1;
  }
`;

const HistoryItem = styled.div`
  margin-bottom: 24px;
`;

export default {
  Options,
  History,
  HistoryItem,
  Crosses,
  Title,
  Question,
  Option,
  OptionIndex,
  CheckBox,
  Button,
  ContentWrapper,
  Top,
  Close,
  Content,
  Scroll,
  List,
  CrossOption,
  Cross,
  CrossOptionText,
};
