import styled, { css } from "styled-components";
import { Collapse as AntCollapse } from "antd";
import Link from "components/Link/Link";
import { MEDIA } from "constants/media";

const CollapsePanel = AntCollapse.Panel;

const ModulePanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModulePanelTitle = styled.div`
  font-weight: bold;
`;

const Panel = styled(CollapsePanel)`
  &&& {
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 26px;
  }
  .ant-collapse-header-text {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
`;

const CollapseIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  background: #f5f5f5;
  text-align: center;
  .anticon {
    font-size: 10px;
    transform: translateY(-3px);
  }
`;

const Collapse = styled(AntCollapse)`
  padding: 0;
  width: 430px;
  ${MEDIA.lgAndLess} {
    width: 300px;
  }
  ${MEDIA.smAndLess} {
    width: 100%;
  }
  &&& .ant-collapse-ghost > .ant-collapse-item {
    border-bottom: 1px solid #e4e4e4;
  }
  &&& .ant-collapse-content-box {
    padding: 0;
    margin-bottom: 26px;
  }
  &&& .ant-collapse-header {
    padding: 0 0 26px;
  }

  &&& .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-bottom: 0;
  }
`;

const Lesson = styled(Link)`
  padding: 16px;
  background: ${({ $isActive }) => ($isActive ? "#E5F2E5" : "#f4f2f6")};
  border: 1px solid ${({ $isActive }) => ($isActive ? "#16A513" : "f4f2f6")};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: initial;
  }
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ $isDone }) =>
    $isDone &&
    css`
      opacity: 0.7;
    `}
`;

const LessonNumber = styled.div`
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 18px;
  margin-right: 18px;
  padding-top: 2px;
  background: #ffffff;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.5);
`;

const LessonLocked = styled.div`
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 18px;
  margin-right: 18px;
  padding-top: 2px;
  background: #ffffff;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.5);
`;

const LessonDone = styled.div`
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 18px;
  margin-right: 18px;
  padding-top: 2px;
  background: #ffffff;
  border-radius: 20px;
  color: #16a513;
  border: 1px solid #16a513;
`;

const LessonContent = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
`;

const LessonTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  font-weight: 500;
`;

const LessonTopNumber = styled.div`
  color: #7a00e6;
  text-transform: uppercase;
  font-weight: 500;
`;

const LessonTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
  color: #000;
`;

const LessonType = styled.div`
  padding: 6px 8px;
  background: #ffffff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  width: fit-content;
  text-transform: uppercase;
  & > * {
    vertical-align: middle;
  }
  img {
    margin-right: 11px;
  }
`;

export default {
  ModulePanel,
  ModulePanelTitle,
  Panel,
  CollapseIcon,
  Collapse,
  Lesson,
  LessonNumber,
  LessonContent,
  LessonTopRow,
  LessonTopNumber,
  LessonTitle,
  LessonType,
  LessonDone,
  LessonLocked,
};
