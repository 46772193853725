import * as progressActions from "./progress.actions";
import initialState from "store/initialState";

export default (state = initialState.progress, action) => {
  switch (action.type) {
    case progressActions.UPDATE_PROGRESS_SUCCESS:
    case progressActions.GET_PROGRESS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
