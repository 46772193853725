import React from "react";
import { Link as DOMLink } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(DOMLink)`
  color: #7a00e6;
  &:hover {
    color: #22004b;
  }
`;

const Link = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

export default Link;
