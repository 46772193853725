import styled from "styled-components";
import { MEDIA } from "constants/media";
import { Link } from "react-router-dom";

const Page = styled.section`
  padding-top: 50px;
`;

const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    padding: 0 15px;
    max-width: 90%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  ${MEDIA.smAndLess} {
    flex-direction: column;
  }
`;

const Aside = styled.aside`
  width: 430px;
  ${MEDIA.lgAndLess} {
    width: 300px;
  }
  ${MEDIA.smAndLess} {
    width: 100%;
  }
`;

const LessonContent = styled.div`
  padding-left: 30px;
  width: calc(100% - 430px);

  ${MEDIA.lgAndLess} {
    width: calc(100% - 300px);
  }
  ${MEDIA.smAndLess} {
    width: 100%;
  }
`;

export default {
  Page,
  Container,
  Content,
  Aside,
  LessonContent,
};
