import sortBy from "lodash/sortBy";

export const isLessonDisabled = (lessonId, currentId, currentProgress) => {
  return !currentProgress?.finishedLessons?.includes(currentId);
};

export const isLessonFinished = (currentProgress, id) =>
  currentProgress?.finishedLessons?.includes(id);

export const mapIdsToLessons = (modules) =>
  sortBy(modules, "rank")?.reduce((acc, module) => {
    return [
      ...acc,
      ...module?.lessons.map((lesson) => ({ ...lesson, moduleId: module?.id })),
    ];
  }, []);

export const isLessonCurrentActive = (
  lessonsWithIds,
  currentProgress,
  lessonId
) => {
  // const lastPassedLessonID =
  //   currentProgress?.finishedLessons?.[
  //     currentProgress?.finishedLessons?.length - 1
  //   ];

  const lastPassedLessonID = currentProgress?.finishedLessons?.[0];

  const lastPassedIndex = lessonsWithIds?.findIndex(
    (l) => `${l?.id}` === `${lastPassedLessonID}`
  );
  const currentActiveLesson = lessonsWithIds?.[lastPassedIndex + 1];
  return (
    `${currentActiveLesson?.id}` === `${lessonId}` &&
    !currentProgress?.finishedLessons.includes(lessonId)
  );
};

export const getNextLesson = (currentProgress, lessonsWithModulesID) => {
  const lastPassedLessonID =
    currentProgress?.finishedLessons?.[
      currentProgress?.finishedLessons?.length - 1
    ];

  const nextLessonIndex = !!currentProgress?.finishedLessons.length
    ? lessonsWithModulesID.findIndex((l) => l?.id === lastPassedLessonID) + 1
    : 0;
  return lessonsWithModulesID[nextLessonIndex];
};
