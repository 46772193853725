import * as finalAssessmentActions from "./finalAssessment.actions";
import initialState from "store/initialState";

export default (state = initialState.courses, action) => {
  switch (action.type) {
    case finalAssessmentActions.GET_FINAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        test: action.payload,
      };
    case finalAssessmentActions.SUBMIT_FINAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        result: action.payload,
      };
    case finalAssessmentActions.CLEAR_FINAL_ASSESSMENT:
      return {
        ...state,
        result: null,
      };
    default:
      return state;
  }
};
