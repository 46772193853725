import React, {useEffect} from "react";

import {useSelector} from "react-redux";
import {useParams} from "react-router";

import {
  courseSelector,
  lessonSelector,
} from "services/courses/courses.selectors";
import {buildPath} from "../../routes/urlLocations";
import LessonVideo from "./Lesson.Video/Lesson.Video";
import LessonSlides from "./Lesson.Slides/Lesson.Slides";
import Course from "containers/Course/Course";
import Styled from "./styled";
import {ReactComponent as ArrowIcon} from "assets/img/arrow.svg";
import {CloseOutlined} from "@ant-design/icons";
import {progressSelector} from "../../services/progress/progress.selector";

const renderLesson = (lesson) =>
  ({
    video: <LessonVideo lesson={lesson}/>,
    slides: <LessonSlides lesson={lesson}/>,
  }[lesson.type]);

const Lesson = () => {
  const lesson = useSelector(lessonSelector);
  const course = useSelector(courseSelector);
  const {lessonId, courseId} = useParams();
  const progress = useSelector(progressSelector);

  window.addEventListener("resize", () => {
    const param = window.innerWidth < 768 && lessonId ? "hidden" : "auto";
    document.body.style.overflow = param;
  });

  useEffect(() => {
    if (window.innerWidth < 768 && lessonId) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [lessonId]);

  const lessonsIds = course?.modules?.length
    ? course.modules
      ?.map(({lessons}) => {
        return [...lessons?.map(({id}) => `${id}`)];
      })
      .flat(Infinity)
    : [];

  const currentLessonIndex = lessonsIds.indexOf(lessonId);
  const nextLessonIndex = currentLessonIndex + 1;
  const nextLessonId = lessonsIds[nextLessonIndex];
  const nextModuleId = course?.modules.find((module) =>
    module?.lessons?.find((l) => `${l?.id}` === `${nextLessonId}`)
  )?.id;

  const downloadableMaterial = lesson?.downloadableMaterial
  // || lesson.type === 'video' ? (lesson?.material?.[0]?.video?.url || lesson?.material?.[0]?.videoURL) : undefined
  return (
    <Course>
      {lessonId && (
        <Styled.CourseLessonContent>
          <Styled.LessonContentWrapper>
            <Styled.CourseLessonTop>
              <Styled.CourseLessonClose to={buildPath.course(courseId)}>
                <CloseOutlined/>
              </Styled.CourseLessonClose>
            </Styled.CourseLessonTop>
            <Styled.LessonTitleWrapper>
              <Styled.Title>{lesson?.title}</Styled.Title>
              {progress?.[courseId]?.finishedLessons?.includes(lesson?.id) && (
                <Styled.Button
                  to={
                    nextLessonId
                      ? buildPath.lesson(courseId, nextModuleId, nextLessonId)
                      : buildPath.assessment(courseId, course?.assessment)
                  }
                >
                  <span>Move to next step</span>
                  <ArrowIcon/>
                </Styled.Button>
              )}
            </Styled.LessonTitleWrapper>
            {lesson && renderLesson(lesson)}
            <Styled.LessonTitleWrapper>
              {downloadableMaterial ?
                <Styled.Download href={downloadableMaterial.url} target={'_blank'}>Download Lesson</Styled.Download> :
                <Styled.Title> </Styled.Title>
              }
              {progress?.[courseId]?.finishedLessons?.includes(lesson?.id) && (
                <Styled.Button
                  to={
                    nextLessonId
                      ? buildPath.lesson(courseId, nextModuleId, nextLessonId)
                      : buildPath.assessment(courseId, course?.assessment)
                  }
                >
                  <span>Move to next step</span>
                  <ArrowIcon/>
                </Styled.Button>
              )}
            </Styled.LessonTitleWrapper>
            {/*<Styled.Capture>Description</Styled.Capture>*/}
            {/*<Styled.Desc>*/}
            {/*  {lesson?.shortDescription || (*/}
            {/*    <>*/}
            {/*      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A*/}
            {/*      accusantium amet animi consectetur cupiditate! At autem*/}
            {/*      consequuntur cumque deleniti dignissimos eaque expedita harum,*/}
            {/*      itaque magni provident, saepe temporibus ullam! Sed.*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</Styled.Desc>*/}
          </Styled.LessonContentWrapper>
        </Styled.CourseLessonContent>
      )}
    </Course>
  );
};

export default Lesson;
