import instance from "../root.api";

export const getCoursesApi = () =>
  instance.get(`/courses`).then((response) => ({ response }));

export const getCourseApi = (id) =>
  instance.get(`/courses/${id}`).then((response) => ({ response }));

export const getLessonApi = (id) =>
  instance.get(`/lessons/${id}`).then((response) => ({ response }));

export const getAssessmentApi = (id) =>
  instance.get(`/assessments/${id}`).then((response) => ({ response }));

export const submitAssessmentAnswersApi = ({ id, answers }) => {
  return instance
    .post(
      `/assessments/${id}/answer`,
      { answers },
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    )
    .then(({ data }) => data);
};
