import styled from "styled-components";

const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;

const ButtonRow = styled.div`
  text-align: right;
`;

export default {
  FieldWrapper,
  ButtonRow,
};
