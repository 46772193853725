import styled from "styled-components";
import MainBG from "../../assets/img/mainBG.jpg";
import { MEDIA } from "constants/media";

const Main = styled.div`
  display: flex;
  min-height: 100vh;
  padding-top: 94px;
  padding-bottom: 70px;
  ${MEDIA.mdAndLess} {
    min-height: auto;
  }
`;

const Header = styled.header`
  margin-bottom: -94px;
  position: relative;
  z-index: 1;
  ${MEDIA.mdAndLess} {
    margin-top: 0;
  }
`;

const HeaderContainer = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  border-bottom: 1px solid #7a00e6;
  padding: 12px 0 12px;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  } 
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderLogo = styled.img`
  height: 60px;
`;

const AppLogo = styled.img`
  height: 40px;
`;

const MainContainer = styled.div`
  max-width: 1360px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  height: 100vh;
  min-height: 800px;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
  ${MEDIA.mdAndLess} {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }
`;

const MainInfo = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${MEDIA.mdAndLess} {
    padding-top: 125px;
    padding-bottom: 50px;
    width: 100%;
  }
`;

const MainInfoBG = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url(${MainBG});
  left: -50%;
  background-size: cover;
  ${MEDIA.mdAndLess} {
    left: -5%;
    right: -5%;
  }
`;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
  justify-content: center;
  ${MEDIA.mdAndLess} {
    width: 100%;
    padding: 50px 0;
  }
`;

const MainBox = styled.div`
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  align-items: center;
  padding: 50px 50px 0;
  width: 450px;
  ${MEDIA.lgAndLess} {
    width: 80%;
    padding: 20px 30px;
  }
  ${MEDIA.xs} {
    width: 100%;
  }
`;

const MainContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const MainTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 24px;
`;

const MainDesc = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

const MainStepNumber = styled.div`
  padding-top: 8px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #7a00e6;
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
  position: relative;
  background: white;
  color: #7a00e6;
  &:after {
    content: "";
    position: absolute;
    border-left: 2px dotted #7a00e6;
    width: 1px;
    height: 50px;
    top: 100%;
    left: calc(50% - 1px);
  }
`;

const MainStep = styled.div`
  margin-bottom: 44px;
  &:last-child {
    ${MainStepNumber}:after {
      display: none;
    }
  }
`;

const MainStepTitle = styled.div`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  max-width: 80%;
`;

const Footer = styled.div`
  position: relative;
  color: #ffffff;
  padding: 24px;
  margin-top: -70px;
  z-index: 1;
  ${MEDIA.mdAndLess} {
    margin: 0;
    padding: 0 0 50px;
    color: #000;
  }
`;

const FooterContainer = styled.div`
  width: 1360px;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
`;

export default {
  Header,
  Main,
  HeaderContainer,
  HeaderLogo,
  AppLogo,
  MainContainer,
  MainInfo,
  MainBlock,
  MainBox,
  MainContent,
  MainInfoBG,
  MainTitle,
  MainDesc,
  MainStep,
  MainStepNumber,
  MainStepTitle,
  Footer,
  FooterContainer,
};
