import * as coursesActions from "./courses.actions";
import initialState from "store/initialState";

export default (state = initialState.courses, action) => {
  switch (action.type) {
    case coursesActions.GET_COURSES_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case coursesActions.GET_COURSE_SUCCESS:
      return {
        ...state,
        course: action.payload,
      };
    case coursesActions.GET_LESSON_SUCCESS:
      return {
        ...state,
        lesson: action.payload,
      };
    case coursesActions.GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessment: action.payload,
      };
    case coursesActions.SUBMIT_ASSESSMENT_ANSWERS_SUCCESS:
      return {
        ...state,
        assessmentResult: action.payload,
      };
    case coursesActions.CLEAR_ASSESSMENT_ANSWERS:
      return {
        ...state,
        assessmentResult: null,
      };
    default:
      return state;
  }
};
