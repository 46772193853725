import styled from "styled-components";
import { Select as AntSelect } from "antd";

const Select = styled(AntSelect)`
  width: 100%;
  &&&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #7a00e6;
  }
  &&& .ant-select-selector {
    background-color: white;
    position: relative;
    padding: 9px 14px 9px 14px;
    color: #000000;
    height: 48px;
    border-radius: 8px;

    border: 1px solid #e4e4e4;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-stretch: normal;
    letter-spacing: normal;
    -webkit-box-shadow: 0 0 0 30px white inset !important;

    &:hover {
      border: 1px solid #7a00e6;
    }
    &:focus,
    &:active {
      border: 1px solid #7a00e6;
      box-shadow: 0 0 2px #7a00e6;
    }
  }
`;

export default Select;
