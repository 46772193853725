import {
  GET_APP_INFO_SUCCESS,
  GET_INTRO_VIDEO_SUCCESS,
} from "./appInfo.action";
import initialState from "../../store/initialState";

export default (state = initialState.appInfo, action) => {
  switch (action.type) {
    case GET_APP_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_INTRO_VIDEO_SUCCESS:
      return {
        ...state,
        introVideo: action.payload,
      };
    default:
      return state;
  }
};
