import { call, put, select, takeEvery } from "redux-saga/effects";
import { createMatchSelector, LOCATION_CHANGE } from "connected-react-router";
import get from "lodash/get";

import * as logsApi from "./logs.api";
import * as logsActions from "./logs.actions";

import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import { isUrlMatch } from "services/router/router.utils";
import { urlLocations } from "../../routes/urlLocations";

function* getSlideLogsOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.lessonInfo)) {
      const getMatch = createMatchSelector(urlLocations.lessonInfo);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.lessonId");
      yield !!id && put({ type: logsActions.GET_SLIDES_LOGS, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateVideoLogs({ payload }) {
  try {
    const { response } = yield call(logsApi.updateVideoLogsRequest, payload);

    yield put({
      type: logsActions.UPDATE_VIDEO_LOGS_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: logsActions.UPDATE_VIDEO_LOGS_FAILURE });
  }
}

function* getSlideLogs({ payload: id }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(logsApi.getSlideLogsRequest, id);

    yield put({
      type: logsActions.GET_SLIDES_LOGS_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: logsActions.GET_SLIDES_LOGS_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateSlideLogs({ payload }) {
  try {
    const { response } = yield call(logsApi.updateSlideLogsRequest, payload);

    yield put({
      type: logsActions.UPDATE_SLIDES_LOGS_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: logsActions.UPDATE_SLIDES_LOGS_FAILURE });
  }
}

export default function* logsSaga() {
  yield takeEvery(LOCATION_CHANGE, getSlideLogsOnLocationChange);
  yield takeEvery(logsActions.UPDATE_VIDEO_LOGS, updateVideoLogs);
  yield takeEvery(logsActions.GET_SLIDES_LOGS, getSlideLogs);
  yield takeEvery(logsActions.UPDATE_SLIDES_LOGS, updateSlideLogs);
}
