import React from "react";
import Input from "../Input/Input";

const InputField = ({
  name,
  placeholder,
  type,
  input,
  meta,
  value,
  ...res
}) => (
  <>
    <Input
      name={name}
      placeholder={placeholder}
      type={type}
      {...input}
      {...res}
    />
    {meta?.touched && meta?.error && <div>{meta?.error}</div>}
  </>
);

export default InputField;
