import React, { useEffect, useState } from "react";

import { Modal } from "antd";
import ProfilePopupForm from "./ProfilePopup.Form";
import { useSelector } from "react-redux";
import { profileSelector } from "services/profile/profile.selectors";
import {isReplica} from "../../constants";

const ProfilePopup = () => {
  const [isVisible, setVisibility] = useState(false);
  const profile = useSelector(profileSelector);
  useEffect(() => {
    profile &&
      setVisibility(
        !profile?.city || !profile?.speciality || !profile?.placeOfWork || !profile?.phoneNumber || (isReplica  && !profile?.numberSCFHS)
      );
  }, [profile]);
  return (
    <Modal visible={isVisible} footer={false} closable={false}>
      <ProfilePopupForm />
    </Modal>
  );
};

export default ProfilePopup;
