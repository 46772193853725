export const MEDIA = {
  xlg: "@media (min-width: 1370px)",
  lg: "@media (min-width: 992px) and (max-width: 1369.98px)",
  md: "@media (min-width: 768px) and (max-width: 991.98px)",
  sm: "@media (min-width: 576px) and (max-width: 767.98px)",
  xs: "@media (max-width: 575.98px)",
  lgAndLess: "@media (max-width: 1369.98px)",
  mdAndLess: "@media (max-width: 991.98px)",
  smAndLess: "@media (max-width: 767.98px)",
};
