import React from "react";

import LoginForm from "./Login.Form";
import Styled from "./styled";
import { urlLocations } from "../../routes/urlLocations";
import {isReplica} from "../../constants";
import ACC from "assets/img/acc.jpg";

const Login = () => {
  return (
    <Styled.Container>
      <Styled.Title>Welcome Back</Styled.Title>
      <LoginForm />
      {isReplica && <Styled.ForgotPasswordLinkWrapper>
        <Styled.ForgotPasswordLink to={urlLocations.register}>
          Creat New Account
        </Styled.ForgotPasswordLink>
      </Styled.ForgotPasswordLinkWrapper>}

      <Styled.Desc>
        By continuing you agree to our <a href="#">T&Cs.</a> Please also check
        out our <a href="https://www.campus.sanofi/sa/footer-links/privacy-policy">Privacy Policy</a>. We use your data to offer you a
        personalised experience and to better understand and improve our
        services. <a href="">For more information see here.</a>
      </Styled.Desc>

      {isReplica && <Styled.AccIMG src={ACC}/>}
    </Styled.Container>
  );
};

export default Login;
