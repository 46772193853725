import React from "react";
import { Switch } from "react-router";

import { urlLocations } from "./urlLocations";
import RouteWrapper from "./RouterWrapper";
import MainLayout from "../layouts/MainLayout/MainLayout";
import Containers from "./containers";
import AuthorizedLayout from "layouts/AuthorizedLayout/AuthorizedLayout";

export default () => (
  <>
    <Switch>
      <RouteWrapper
        exact
        path={urlLocations.login}
        layout={MainLayout}
        component={Containers.Login}
      />
      <RouteWrapper
        exact
        path={urlLocations.forgotPassword}
        layout={MainLayout}
        component={Containers.ForgotPassword}
      />
      <RouteWrapper
        exact
        path={urlLocations.register}
        layout={MainLayout}
        component={Containers.Register}
      />
      <RouteWrapper
        exact
        path={urlLocations.home}
        layout={AuthorizedLayout}
        component={Containers.Home}
      />
      <RouteWrapper
        exact
        path={urlLocations.lessonInfo}
        layout={AuthorizedLayout}
        component={Containers.Lesson}
      />
      <RouteWrapper
        exact
        path={urlLocations.assessmentInfo}
        layout={AuthorizedLayout}
        component={Containers.Assessment}
      />
      <RouteWrapper
        exact
        path={urlLocations.finalAssessment}
        layout={AuthorizedLayout}
        component={Containers.FinalAssessment}
      />
      <RouteWrapper
        exact
        path={urlLocations.success}
        layout={AuthorizedLayout}
        component={Containers.SuccessPage}
      />
    </Switch>
  </>
);
