import instance from "../root.api";

export const updateVideoLogsRequest = (data) =>
  instance.post(`/watch-logs`, data).then((response) => ({ response }));

export const getSlideLogsRequest = (id) =>
  instance.get(`/slides-logs/${id}`).then((response) => ({ response }));

export const updateSlideLogsRequest = (data) =>
  instance.post(`/slides-logs`, data).then((response) => ({ response }));
