export default {
  appInfo: null,
  loader: false,
  profile: null,
  courses: {
    list: null,
    course: null,
    lesson: null,
    assessment: null,
    assessmentResult: null,
  },
  progress: null,
  finalAssessment: {
    test: null,
    result: null,
  },
  logs: {
    slidesLogs: null,
  },
};
