import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store from "./store";
import { history } from "./routes/urlLocations";
import Loader from "./components/Loader/Loader";

import Routes from "./routes/routes";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import ScrollToTop from "./hooks/scrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Loader>
        <ScrollToTop />
        <Routes />
      </Loader>
    </ConnectedRouter>
  </Provider>
);

serviceWorker.unregister();
