import React from "react";

import { CheckOutlined } from "@ant-design/icons";
import { countBy } from "lodash";
import { useSelector } from "react-redux";

import Styled from "./styled";
import { coursesSelector } from "services/courses/courses.selectors";
import { buildPath, urlLocations } from "routes/urlLocations";

const AssessmentResult = ({ result, clearData, courseId, isPreAssessment }) => {
  const countAnswers = countBy(result?.answersDetails, "isCorrect");
  const detailsArr = Object.values(result?.answersDetails);
  const courses = useSelector(coursesSelector);
  const course = courses.find(c => c?.id == courseId)
  console.log({course, courseId})

  return (
    <div>
      {isPreAssessment ?
        (<Styled.SuccessRow>
          <div>
            <Styled.PreAssessmentScore>
              {countAnswers.true || 0} out of {detailsArr?.length} answers are correct
            </Styled.PreAssessmentScore>
          </div>
          <Styled.SuccessButton
            to={
                buildPath.lesson(
                    course?.id,
                    course?.modules?.[0].id,
                    course.modules?.[0].lessons?.[0].id
                  )
            }
          >
            Start The Course
          </Styled.SuccessButton>
        </Styled.SuccessRow>) :
      result?.passed ? (
        <Styled.SuccessRow>
          <Styled.SuccessIcon>
            <CheckOutlined />
          </Styled.SuccessIcon>
          <div>
            <Styled.SuccessTitle>Congratulation!</Styled.SuccessTitle>
            <Styled.SuccessDesc>
              You successfully passed the assessment
            </Styled.SuccessDesc>
            <Styled.SuccessScore>
              😎 {countAnswers.true} out of {detailsArr?.length} answers correct
            </Styled.SuccessScore>
          </div>
          <Styled.SuccessButton
            to={
              // nextLesson
              //   ? buildPath.lesson(
              //       nextCourse?.id,
              //       nextLesson?.moduleId,
              //       nextLesson?.id
              //     )
              //   : urlLocations.finalAssessment
              urlLocations.homeCourses
            }
          >
            Move to next step
          </Styled.SuccessButton>
        </Styled.SuccessRow>
      ) : (
        <Styled.FailureRow>
          <div>
            <Styled.FailureTitle>Oh no..</Styled.FailureTitle>
            <Styled.FailureDesc>
              Unfortunately you didn't passed the assessment
            </Styled.FailureDesc>
            <Styled.FailureScore>
              🤔 Your score is {result?.score}% ({countAnswers.true}/{detailsArr?.length}),<br/> You need to score at lease {result?.requiredScore}% correct
            </Styled.FailureScore>
          </div>
          <Styled.FailureButton onClick={() => clearData()}>
            Retry again
          </Styled.FailureButton>
        </Styled.FailureRow>
      )}
    </div>
  );
};

export default AssessmentResult;
