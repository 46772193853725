import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import {
  GET_APP_INFO,
  GET_APP_INFO_FAILURE,
  GET_APP_INFO_SUCCESS,
  GET_INTRO_VIDEO,
  GET_INTRO_VIDEO_FAILURE,
  GET_INTRO_VIDEO_SUCCESS,
} from "./appInfo.action";

import { appInfoRequest, introVideoRequest } from "./appInfo.api";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";

function* getAppInfoOnLocationChangeData() {
  try {
    const appInfo = yield select((state) => state.appInfo);
    if (isEmpty(appInfo)) yield put({ type: GET_APP_INFO });
  } catch (error) {
    console.log(error);
  }
}

function* getIntroVideoOnLocationChangeData() {
  try {
    const video = yield select((state) => state.appInfo?.introVideo);
    if (isEmpty(video)) yield put({ type: GET_INTRO_VIDEO });
  } catch (error) {
    console.log(error);
  }
}

function* fetchIntoVideo() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(introVideoRequest);
    yield put({
      type: GET_INTRO_VIDEO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: GET_INTRO_VIDEO_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchAppInfo() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(appInfoRequest);
    yield put({
      type: GET_APP_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: GET_APP_INFO_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* appInfoSaga() {
  yield takeLatest(LOCATION_CHANGE, getAppInfoOnLocationChangeData);
  yield takeLatest(LOCATION_CHANGE, getIntroVideoOnLocationChangeData);
  yield takeLatest(GET_INTRO_VIDEO, fetchIntoVideo);
  yield takeLatest(GET_APP_INFO, fetchAppInfo);
}
