import React from "react";

import styled, { css } from "styled-components";
import { Input as AntInput } from "antd";

const StyledInput = styled(({ ...props }) => (
  <AntInput autoComplete="off" {...props} />
))`
  && {
	    background-color: white;
	    position: relative;
	    padding: 17px 14px 9px 14px;
	    color: #000000;
	    height: 48px;
	    border-radius: 8px;
	    border: 1px solid #E4E4E4;
	    font-style: normal;
		font-size: 14px;
		line-height: 18px;
	    font-stretch: normal;
	    letter-spacing: normal;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    &&& .ant-input {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
	    + label {
	        transition: all 0.2s ease-in;
	    }
	    
	      ${({ value }) => {
          if (!!value) {
            return css`
              & + label {
                top: 6px !important;
                font-size: 9px !important;
              }
            `;
          }
        }}}
	    &:hover {
	      border: 1px solid #7A00E6 !important;
	    }
	    &:focus,
	    &:active {
	      border: 1px solid #7A00E6;
          box-shadow : 0 0 2px #7A00E6;
	      
	      & + label {
	        top: 6px !important;
	        font-size: 9px !important;
	      }
	    }

	    .ant-input-focused {
	      border: 1px solid #E4E4E4;
        }
	
	    &[disabled] {
	      border: 1px solid grey;
	      opacity: 0.9;
	    }
	    .ant-input-prefix {
	      width: 22px;
	      height: 22px;
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      margin-right: 8px;
	    }
	
	    &.ant-input-sm {
	      height: 32px;
	      padding: 8px 16px;
	    }
	}
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  top: 6px;
  left: 16px;
  position: absolute;
  z-index: 2;
  width: 100%;
  color: #9a9fad;
  pointer-events: none;
  font-size: 9px;
`;

export default {
  Input: StyledInput,
  Wrapper,
  Label,
};
