import styled from "styled-components";
import BG from "assets/img/successBG.jpg";
import { MEDIA } from "constants/media";

const Header = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background: #f4f2f6;
`;

const HeaderTop = styled.div`
  padding-top: 160px;
  background: url(${BG});
  background-size: cover;
  text-align: center;
`;

const Container = styled.div`
  width: 1360px;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    max-width: 95%;
  }
`;

const Page = styled.div`
  padding: 40px 0 100px;
`;

const HeaderLogo = styled.div`
  display: inline-block;
  width: 102px;
  height: 102px;
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
  position: relative;
  padding: 15px;
  transform: translateY(52px);
  border: 2px solid #16a513;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateY(-5px);
  }
`;

const HeaderContent = styled.div`
  padding: 50px 10px 60px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
`;

const ScoreWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

const Score = styled.div`
  display: inline-block;
  color: #16a513;
  font-size: 18px;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: bold;
`;

const Desc = styled.div`
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
`;

const SurveyLink = styled.a`
  text-align: center;
`;

const HeaderWrapperLogo = styled.div`
  position: relative;
  display: inline-block;
`;

const SuccessIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #16a513;
  transform: translateY(52px);
  text-align: center;
  padding: 2px;
  .anticon {
    font-size: 10px;
    color: #ffffff;
  }
`;

export default {
  Container,
  Header,
  HeaderTop,
  Page,
  HeaderLogo,
  HeaderContent,
  Title,
  ScoreWrapper,
  Score,
  Desc,
  SurveyLink,
  HeaderWrapperLogo,
  SuccessIcon,
};
