import React from "react";
import { LockOutlined } from "@ant-design/icons";

import Styled from "./styled";
import CourseBG1 from "assets/img/course1BG.jpg";
import Link from "components/Link/Link";
import { buildPath } from "routes/urlLocations";
import { ReactComponent as ArrowIcon } from "assets/img/arrow.svg";
import { useSelector } from "react-redux";
import { progressSelector } from "services/progress/progress.selector";
import { getNextLesson, mapIdsToLessons } from "../../utils/lessons";

const HomeCourseCard = ({ course, prevCourseId }) => {
  const progress = useSelector(progressSelector);
  const currentProgress = progress?.[course?.id];
  const prevProgress = progress?.[prevCourseId];
  const isActive = !prevCourseId || prevProgress?.isCompleted;
  const isInProgress = isActive && !currentProgress?.isCompleted;

  const lessonsWithModulesID = mapIdsToLessons(course?.modules);

  const nextLesson = getNextLesson(currentProgress, lessonsWithModulesID);

  const passToLesson = () => {
    if (currentProgress?.isCompleted) {
      return buildPath.lesson(
        course?.id,
        lessonsWithModulesID?.[0]?.moduleId,
        lessonsWithModulesID?.[0]?.id
      );
    }
    return progress?.[course?.id]?.finishedLessons?.length ===
      lessonsWithModulesID?.length
      ? buildPath.assessment(course?.id, course?.assessment)
      : buildPath.lesson(course?.id, nextLesson?.moduleId, nextLesson?.id);
  };

  const progressCount = currentProgress?.assessment
    ? currentProgress?.finishedCount + 1
    : currentProgress?.finishedCount;

  const percents = Math.floor(
    (progressCount / (parseInt(currentProgress?.courseLessonsCount) + 1)) * 100
  );

  return (
    <Styled.CourseCard isInProgress={isInProgress} isActive={isActive}>
      <Styled.CourseCardHeader isDisabled={!isActive} bg={CourseBG1}>
        <Styled.CourseHeaderTopRow>
          <Styled.CourseHeaderModules>
            {course.modules.length} modules
          </Styled.CourseHeaderModules>
        </Styled.CourseHeaderTopRow>
        <Styled.CourseHeaderLogo>
          <img src={course?.logo?.url} alt="" />
        </Styled.CourseHeaderLogo>
      </Styled.CourseCardHeader>
      <Styled.CourseCardContent>
        <Styled.CourseCardInfo isDisabled={!isActive}>
          <Styled.CourseCardNumber>
            Course 0{course?.rank}
          </Styled.CourseCardNumber>
          <Styled.CourseCardTitle>{course?.title}</Styled.CourseCardTitle>
          <Styled.CourseCardDesc>
            {course?.shortDescription}
          </Styled.CourseCardDesc>
          <Styled.CourseCardStatus>
            {isActive && (
              <>
                {currentProgress?.isCompleted ? (
                  <Styled.CourseCardStatusFinished>
                    <div>
                      <Styled.CourseCardFinishIcon twoToneColor={"#16a513"} />
                      <Styled.CourseCardFinish>
                        Finished
                      </Styled.CourseCardFinish>
                    </div>
                    <Styled.CourseCardGrade>
                      Grade: {lessonsWithModulesID?.length}/
                      {lessonsWithModulesID?.length}
                    </Styled.CourseCardGrade>
                  </Styled.CourseCardStatusFinished>
                ) : (
                  <>
                    <Styled.StatusProgress>
                      <Styled.StatusProgressInner progress={percents} />
                    </Styled.StatusProgress>
                    <Styled.StatusProgressInfo>
                      <span>In Progress</span>
                      <span>{percents}%</span>
                    </Styled.StatusProgressInfo>
                  </>
                )}
              </>
            )}
          </Styled.CourseCardStatus>
        </Styled.CourseCardInfo>
      </Styled.CourseCardContent>
      <Styled.CourseCardFooter>
        <>
          {isActive ? (
            <Styled.ExploreMore>
              <Link to={passToLesson()}>
                Explore More
                <ArrowIcon />
              </Link>
            </Styled.ExploreMore>
          ) : (
            <Styled.CourseCardBlockedLink>
              <Styled.CourseCardBlockedLinkIcon>
                <LockOutlined />
              </Styled.CourseCardBlockedLinkIcon>
              <Styled.CourseCardBlockedText>
                For opening this course you should pass the previous one
              </Styled.CourseCardBlockedText>
            </Styled.CourseCardBlockedLink>
          )}
        </>
      </Styled.CourseCardFooter>
    </Styled.CourseCard>
  );
};

export default HomeCourseCard;
