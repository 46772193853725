import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import { history } from "../routes/urlLocations";

import { LOGOUT } from "./logout/logout.action";
import initialState from "../store/initialState";
import loaderReducer from "./loader/loader.reducer";
import profileReducer from "./profile/profile.reducer";
import coursesReducer from "./courses/courses.reducer";
import progressReducer from "./progress/progress.reducer";
import finalAssessmentReducer from "./finalAssessment/finalAssessment.reducer";
import appInfoReducer from "./appInfo/appInfo.reducer";
import logsReducer from "./logs/logs.reducer";

const appReducer = combineReducers({
  appInfo: appInfoReducer,
  loader: loaderReducer,
  form: formReducer,
  profile: profileReducer,
  courses: coursesReducer,
  progress: progressReducer,
  finalAssessment: finalAssessmentReducer,
  logs: logsReducer,
  router: connectRouter(history),
});

const rootReducers = (state, action) => {
  if (action.type === LOGOUT) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducers;
