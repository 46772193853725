import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { history } from "routes/urlLocations";

import * as finalAssessmentApi from "./finalAssessment.api";
import * as finalAssessmentActions from "./finalAssessment.actions";
import isEmpty from "lodash/isEmpty";

import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import { isUrlMatch } from "services/router/router.utils";
import { urlLocations } from "../../routes/urlLocations";
import { finalAssessmentResultSelector } from "./finalAssessment.selectors";

function* clearAssessmentResultOnLocationChange() {
  try {
    const result = yield select(finalAssessmentResultSelector);
    if (!isEmpty(result)) {
      yield put({ type: finalAssessmentActions.CLEAR_FINAL_ASSESSMENT });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFinalAssessmentOnLocationChangeData({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.finalAssessment)) {
      yield put({ type: finalAssessmentActions.GET_FINAL_ASSESSMENT });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchFinalAssessment() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(finalAssessmentApi.getFinalAssessmentApi);

    yield put({
      type: finalAssessmentActions.GET_FINAL_ASSESSMENT_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: finalAssessmentActions.GET_FINAL_ASSESSMENT_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitFinalAssessment({ payload: answers }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(
      finalAssessmentApi.submitFinalAssessmentAnswerApi,
      answers
    );
    response.data?.passed && history.push(urlLocations.success);
    yield put({
      type: finalAssessmentActions.SUBMIT_FINAL_ASSESSMENT_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: finalAssessmentActions.SUBMIT_FINAL_ASSESSMENT_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* finalAssessmentSaga() {
  yield takeEvery(LOCATION_CHANGE, clearAssessmentResultOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getFinalAssessmentOnLocationChangeData);
  yield takeEvery(
    finalAssessmentActions.SUBMIT_FINAL_ASSESSMENT,
    submitFinalAssessment
  );
  yield takeEvery(
    finalAssessmentActions.GET_FINAL_ASSESSMENT,
    fetchFinalAssessment
  );
}
