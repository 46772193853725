import * as profileActions from "./profile.actions";
import initialState from "store/initialState";

export default (state = initialState.profile, action) => {
    switch (action.type) {
        case profileActions.GET_PROFILE_DATA_SUCCESS:
        case profileActions.UPDATE_PROFILE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};
