import styled, { css } from "styled-components";

const SliderWrapper = styled.div`
  position: relative;
  &&& .thumbs {
    padding: 0;
  }
  .carousel .thumbs-wrapper {
    margin: 26px 0;
  }
  &&& .thumb {
    cursor: pointer;
    &:hover {
      border-color: #7a00e6;
    }
    img {
      opacity: 0.2;
    }
    &.selected {
      border-color: #7a00e6;
      img {
        opacity: 1;
      }
    }
  }
`;

const Arrow = css`
  z-index: 2;
  position: absolute;
  top: 200px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 12px;
  cursor: pointer;
`;

const ArrowPrev = styled.div`
  ${Arrow};
  left: 24px;
`;

const ArrowNext = styled.div`
  ${Arrow};
  right: 24px;
  img {
    transform: rotate(180deg);
  }
`;

const Note = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  text-align: left;
`;

const NoteTitle = styled.div`
  font-weight: bold;
  margin-right: 16px;
`;

const Status = styled.div`
  background: #000000;
  color: #ffffff;
  padding: 6px 10px;
  border-radius: 6px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
`;

export default {
  Note,
  NoteTitle,
  SliderWrapper,
  ArrowPrev,
  ArrowNext,
  Status,
};
