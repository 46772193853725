import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import Styled from "./styled";
import InputField from "components/InputField/InputField";
import {loginAction, registerAction} from "services/login/login.action";
import EnvelopeIcon from "assets/img/envelopeIcon.svg";
import FullNameIcon from "assets/img/userFullName.png";
import PasswordShow from "assets/img/PasswordShow.svg";
import LockIcon from "assets/img/Lock.svg";
import { ReactComponent as ArrowIcon } from "assets/img/arrow.svg";
import Checkbox from "../../components/Checkbox/Checkbox";

const RegisterForm = ({ handleSubmit }) => {
  const [isPasswordShow, setInputType] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <Styled.InputWrap>
        <Field
          prefix={<img src={FullNameIcon} alt="" />}
          component={InputField}
          name="firstName"
          label="First Name"
          type="text"
        />
      </Styled.InputWrap>
      <Styled.InputWrap>
        <Field
          prefix={<img src={FullNameIcon} alt="" />}
          component={InputField}
          name="lastName"
          label="Last Name"
          type="text"
        />
      </Styled.InputWrap>
      <Styled.InputWrap>
        <Field
          prefix={<img src={EnvelopeIcon} alt="" />}
          component={InputField}
          name="email"
          label="email"
          type="email"
        />
      </Styled.InputWrap>
      <Styled.InputWrap>
        <Field
          prefix={<img src={LockIcon} alt="" />}
          suffix={
            <img
              src={PasswordShow}
              alt=""
              onMouseDown={() => setInputType(true)}
              onMouseUp={() => setInputType(false)}
            />
          }
          component={InputField}
          name="password"
          label="Password"
          type={isPasswordShow ? "text" : "password"}
        />
      </Styled.InputWrap>
      <Styled.InputWrap>
        <Field
          prefix={<img src={LockIcon} alt="" />}
          suffix={
            <img
              src={PasswordShow}
              alt=""
              onMouseDown={() => setInputType(true)}
              onMouseUp={() => setInputType(false)}
            />
          }
          component={InputField}
          name="confirmPassword"
          label="Confirm Password"
          type={isPasswordShow ? "text" : "password"}
        />
      </Styled.InputWrap>
      <Styled.LoginButton htmlType="submit">
        Creat Account
        <ArrowIcon />
      </Styled.LoginButton>
    </form>
  );
};

export default reduxForm({
  form: "registerForm",
  onSubmit: (value, dispatch) => dispatch(registerAction(value)),
})(RegisterForm);
