import React, { useEffect, useState } from "react";
import { Select as AntSelect } from "antd";

import Styled from "./styled";
import Select from "components/Select/styled";

const { Option } = AntSelect;

const AssessmentCross = ({ question, answers, setAnswers, result, isPreAssessment }) => {
  const [value, setValue] = useState({});
  const currentAnswersDetails = result?.answersDetails?.[question?.id];

  useEffect(() => {
    !result?.answersDetails?.[question?.id] && setValue({});
  }, [result?.answersDetails?.[question?.id]]);

  useEffect(() => {
    setAnswers({
      ...answers,
      [question?.id]: value,
    });
  }, [value]);

  return (
    <>
      <Styled.Crosses>
        {question?.leftOptions?.map((option) => (
          <Styled.Cross key={option?.id}>
            <Styled.CrossOption
              $isCorrect={!isPreAssessment && currentAnswersDetails?.isCorrect}
              $isIncorrect={
                !isPreAssessment && currentAnswersDetails && !currentAnswersDetails?.isCorrect
              }
            >
              <Select
                disabled={!!currentAnswersDetails}
                value={value?.[option?.id]}
                onSelect={(v) =>
                  setValue({
                    ...value,
                    [option?.key]: v,
                  })
                }
              >
                {question?.rightOptions?.map((rightOption) => (
                  <Option key={rightOption.key} value={rightOption.key} disabled={!!currentAnswersDetails}>
                    {rightOption.key}
                  </Option>
                ))}
              </Select>
            </Styled.CrossOption>
            <Styled.CrossOptionText>{option.String}</Styled.CrossOptionText>
          </Styled.Cross>
        ))}
      </Styled.Crosses>
      <Styled.History>
        {question?.rightOptions?.map((rOption) => (
          <Styled.HistoryItem key={rOption?.key}>
            <b>
              {rOption.key}
              {". "}
            </b>

            {rOption.String}
          </Styled.HistoryItem>
        ))}
      </Styled.History>
    </>
  );
};

export default AssessmentCross;
