import React from 'react';
import Styled from "../styled";

const DateTimeDisplay = ({ value, type, isExpired }) => {
  return (
    <Styled.CountDownItem>
      <Styled.CountDownNumber isExpired={isExpired}>{value}</Styled.CountDownNumber>
      <Styled.CountDownText>{type}</Styled.CountDownText>
    </Styled.CountDownItem>
  );
};

export default DateTimeDisplay;
