import { createAction } from "redux-actions";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";

export const GET_LESSON = "GET_LESSON";
export const GET_LESSON_SUCCESS = "GET_LESSON_SUCCESS";
export const GET_LESSON_FAILURE = "GET_LESSON_FAILURE";

export const GET_ASSESSMENT = "GET_ASSESSMENT";
export const GET_ASSESSMENT_SUCCESS = "GET_ASSESSMENT_SUCCESS";
export const GET_ASSESSMENT_FAILURE = "GET_ASSESSMENT_FAILURE";

export const SUBMIT_ASSESSMENT_ANSWERS = "SUBMIT_ASSESSMENT_ANSWERS";
export const SUBMIT_ASSESSMENT_ANSWERS_SUCCESS =
  "SUBMIT_ASSESSMENT_ANSWERS_SUCCESS";
export const SUBMIT_ASSESSMENT_ANSWERS_FAILURE =
  "SUBMIT_ASSESSMENT_ANSWERS_FAILURE";

export const CLEAR_ASSESSMENT_ANSWERS = "CLEAR_ASSESSMENT_ANSWERS";

export const submitAssessmentAnswersAction = createAction(
  SUBMIT_ASSESSMENT_ANSWERS
);

export const clearAssessmentResultAction = createAction(
  CLEAR_ASSESSMENT_ANSWERS
);
