import {call, put, takeEvery} from "redux-saga/effects";

import {STORAGE_TYPE} from "../../constants";
import {LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, REGISTER, REGISTER_FAILURE, REGISTER_SUCCESS} from "./login.action";
import {loginRequest, registerRequest} from "./login.api";
import {history, urlLocations} from "../../routes/urlLocations";
import instance from "../root.api";
import {finishLoaderAction, startLoaderAction} from "../loader/loader.action";
import openNotification, {
  prepareErrorMessages,
} from "../../utils/notifications";

function* login({payload: {identifier, password, rememberMe}}) {
  try {
    yield put(startLoaderAction());

    const {data, status} = yield call(loginRequest, {identifier, password});
    if (status && status === 200) {
      localStorage.setItem(
        "storageTyp",
        rememberMe ? STORAGE_TYPE.LOCAL_STORAGE : STORAGE_TYPE.SESSION_STORAGE
      );
      const storage = localStorage.getItem("storageTyp");
      window[storage].setItem("jwt", data.jwt);
      instance.defaults.headers.common["Authorization"] = `Bearer ${data.jwt}`;

      history.push(urlLocations.home);
      yield put({type: LOGIN_SUCCESS});
    }
  } catch ({response}) {
    yield call(openNotification, {
      type: "error",
      message: "Error",
      description: prepareErrorMessages(response),
    });
    yield put({
      type: LOGIN_FAILURE,
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* register({payload}) {
  try {
    yield put(startLoaderAction());

    const {email, firstName, lastName, password} = payload
    const {data, status} = yield call(registerRequest, {email, firstName, lastName, password, username: email});
    if (status && status === 200) {
      localStorage.setItem("storageTyp", STORAGE_TYPE.LOCAL_STORAGE);
      const storage = localStorage.getItem("storageTyp");
      window[storage].setItem("jwt", data.jwt);
      instance.defaults.headers.common["Authorization"] = `Bearer ${data.jwt}`;

      history.push(urlLocations.home);
      yield put({type: REGISTER_SUCCESS});
    }
  } catch ({response}) {
    yield call(openNotification, {
      type: "error",
      message: "Error",
      description: prepareErrorMessages(response),
    });
    yield put({
      type: REGISTER_FAILURE,
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* loginSaga() {
  yield takeEvery(LOGIN, login);
  yield takeEvery(REGISTER, register);
}
