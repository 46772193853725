import { all } from "redux-saga/effects";

import loginSaga from "./login/login.saga";
import logoutSaga from "./logout/logout.saga";
import loaderSaga from "./loader/loader.saga";
import profileSaga from "./profile/profile.saga";
import coursesSaga from "./courses/courses.saga";
import progressSaga from "./progress/progress.saga";
import finalAssessmentSaga from "./finalAssessment/finalAssessment.saga";
import appInfoSaga from "./appInfo/appInfo.saga";
import logsSaga from "./logs/logs.saga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    loaderSaga(),
    logoutSaga(),
    profileSaga(),
    coursesSaga(),
    progressSaga(),
    finalAssessmentSaga(),
    appInfoSaga(),
    logsSaga(),
  ]);
}
