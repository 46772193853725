import styled from "styled-components";
import Link from "components/Link/Link";
import { MEDIA } from "constants/media";

const Header = styled.header`
  padding: 16px 0 16px;
  border-bottom: 1px solid #7a00e633;
`;

const HeaderContainer = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
  ${MEDIA.mdAndLess} {
    flex-direction: column;
  }
`;

const HeaderNav = styled.div`
  display: inline-block;
  ${MEDIA.mdAndLess} {
    text-align: center;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderNavItem = styled(Link)`
  display: inline-block;
  margin: 0 0 0 56px;
  font-size: 18px;
  color: #000000;
  text-decoration: none;
  font-weight: 500;
  ${MEDIA.mdAndLess} {
    margin: 0 0 0 10px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
`;

const HeaderProfile = styled.div`
  position: relative;
  display: inline-flex;
  margin-left: 64px;
  align-items: center;
  cursor: pointer;
  ${MEDIA.mdAndLess} {
    margin: 0;
  }
`;

const HeaderProfileAvatar = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 16px;
  overflow: hidden;
  border-radius: 40%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const HeaderProfileName = styled.div`
  display: inline-block;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
`;

const HeaderLogo = styled(Link)``;

const HeaderLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${MEDIA.mdAndLess} {
    flex-direction: column;
  }
`;

const SponsorLogo = styled.div`
  line-height: 1;
  margin-left: 15px;
  font-weight: bold;
  ${MEDIA.mdAndLess} {
    margin-top: 12px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  img {
    max-height: 60px;
    margin-left: 15px;
  }
`;

const HeaderProfileIcon = styled.div`
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
  .anticon {
    font-weight: bold;
    color: #000000;
    font-size: 13px;
  }
`;

const HeaderMenuWrapper = styled.div`
  padding-top: 10px;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: fit-content;
  z-index: 1;
`;

const HeaderMenu = styled.div`
  padding: 16px;
  text-align: right;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const HeaderMenuEmail = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  font-size: 14px;
`;

const HeaderMenuLogout = styled.div`
  color: #c4112e;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
`;

const Footer = styled.footer`
  padding: 25px 0 38px;
`;

const FooterContainer = styled.div`
  width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
  ${MEDIA.smAndLess} {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterLogo = styled.div`
  margin-bottom: 24px;
  ${MEDIA.smAndLess} {
    text-align: center;
  }
`;

const FooterCopyright = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  ${MEDIA.smAndLess} {
    text-align: center;
  }
`;

const FooterSocials = styled.div`
  text-align: right;
  margin: 0 0 30px;
  ${MEDIA.smAndLess} {
    text-align: center;
    margin: 30px 0;
  }
`;

const FooterSocialItem = styled.a`
  display: inline-block;
  margin: 0 0 0 36px;
  text-decoration: none;
  vertical-align: middle;
  transform: translateY(0px);
  transition: transoform 0.3s;
  &:hover {
    transform: translateY(-1px);
  }
`;

const FooterNav = styled.div`
  text-align: right;
  ${MEDIA.smAndLess} {
    text-align: center;
  }
`;

const FooterNavItem = styled(Link)`
  color: #000000;
  font-size: 14px;
  text-decoration: none;
  margin: 0 0 0 56px;
  ${MEDIA.smAndLess} {
    text-align: center;
    margin: 0 0 0 28px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`;

const FooterNavItemHREF = styled.a`
  color: #000000;
  font-size: 14px;
  text-decoration: none;
  margin: 0 0 0 56px;
  ${MEDIA.smAndLess} {
    text-align: center;
    margin: 0 0 0 28px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`;

const FooterACC = styled.img`
  width: 100px;
  height: auto;
`;

const Bottom = styled.div`
  background: #7a00e6;
  padding: 24px 0;
  color: #ffffff;
`;

const BottomContainer = styled.div`
  width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
  ${MEDIA.smAndLess} {
    flex-direction: column;
  }
`;

const BottomLogo = styled.div`
  margin-right: 32px;
  max-width: 300px;
  ${MEDIA.smAndLess} {
    margin-bottom: 24px;
  }
  img {
    width: 150px;
  }
`;

const BottomText = styled.div`
  font-size: 12px;
  ${MEDIA.smAndLess} {
    max-width: 400px;
  }
`;
const BottomLink = styled.a`
  font-size: 12px;
  color: white;
`;

export default {
  Header,
  HeaderLogo,
  SponsorLogo,
  HeaderLogos,
  HeaderContainer,
  HeaderNavItem,
  HeaderNav,
  HeaderProfile,
  HeaderProfileAvatar,
  HeaderProfileName,
  HeaderProfileIcon,
  HeaderMenu,
  HeaderMenuWrapper,
  HeaderMenuEmail,
  HeaderMenuLogout,
  Footer,
  FooterContainer,
  FooterLogo,
  FooterCopyright,
  FooterSocials,
  FooterSocialItem,
  FooterNav,
  FooterNavItem,
  FooterNavItemHREF,
  FooterACC,
  Bottom,
  BottomContainer,
  BottomLogo,
  BottomText,
  BottomLink,
};
