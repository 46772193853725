import Login from "containers/Login/Login";
import ForgotPassword from "containers/ForgotPassword/ForgotPassword";
import Register from "containers/Register/Register";
import Home from "containers/Home/Home";
import Course from "containers/Course/Course";
import Lesson from "containers/Lesson/Lesson";
import Assessment from "containers/Assessment/Assessment";
import FinalAssessment from "containers/FinalAssessment/FinalAssessment";
import SuccessPage from "containers/SuccessPage/SuccessPage";

export default {
  Login,
  ForgotPassword,
  Register,
  Home,
  Course,
  Lesson,
  Assessment,
  FinalAssessment,
  SuccessPage,
};
