import React, {useEffect, useState} from "react";
import {
  CheckOutlined,
  DownOutlined,
  UpOutlined,
  LockOutlined,
} from "@ant-design/icons";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import sortBy from "lodash/sortBy";

import Styled from "./styled";
import {buildPath} from "routes/urlLocations";
import DocumentIcon from "assets/img/Slide.png";
import VideoIcon from "assets/img/VideoIcon.png";
import MSQIcon from "assets/img/MSQ.png";
import {progressSelector} from "services/progress/progress.selector";
import {
  isLessonDisabled,
  isLessonFinished,
  isLessonCurrentActive,
  mapIdsToLessons,
} from "utils/lessons";

const CourseAside = ({modules, assessment, preAssessment}) => {
  const [expandedTabs, setTabExpand] = useState([]);
  const {courseId, moduleId, lessonId, assessmentId} = useParams();
  const progress = useSelector(progressSelector);
  const currentProgress = progress?.[courseId];

  const lessonWithIds = mapIdsToLessons(modules);

  const TYPE_TO_ICON = {
    slides: DocumentIcon,
    video: VideoIcon,
    file: DocumentIcon,
  };

  const isPanelExpanded = (id) => {
    return expandedTabs.includes(`${id}`);
  };

  useEffect(() => {
    setTabExpand(assessmentId ? [`assessment__${assessmentId}`] : [moduleId]);
  }, [moduleId, lessonId, assessmentId]);

  function renderAssessment() {
    return (
      <Styled.Panel
        showArrow={false}
        key={`assessment__${assessment}`}
        header={
          <Styled.ModulePanel>
            <Styled.ModulePanelTitle>Assessment</Styled.ModulePanelTitle>{" "}
            <Styled.CollapseIcon>
              {assessmentId && assessmentId === assessment ? <UpOutlined/> : <DownOutlined/>}
            </Styled.CollapseIcon>
          </Styled.ModulePanel>
        }
      >
        <Styled.Lesson
          $isDisabled={
            lessonWithIds?.length !== currentProgress?.finishedLessons?.length
          }
          $isActive={assessmentId === `${assessment}`}
          to={buildPath.assessment(courseId, assessment)}
        >
          <>
            {lessonWithIds?.length !==
              currentProgress?.finishedLessons?.length && (
                <Styled.LessonLocked>
                  <LockOutlined/>
                </Styled.LessonLocked>
              )}
            {lessonWithIds?.length ===
              currentProgress?.finishedLessons?.length &&
              !currentProgress?.isCompleted && (
                <Styled.LessonNumber>1</Styled.LessonNumber>
              )}
            {currentProgress?.isCompleted && (
              <Styled.LessonDone>
                <CheckOutlined/>
              </Styled.LessonDone>
            )}
          </>
          <Styled.LessonContent>
            <Styled.LessonTopRow>
              <Styled.LessonTopNumber>Assessment</Styled.LessonTopNumber>
            </Styled.LessonTopRow>
            <Styled.LessonTitle>{assessment?.title}</Styled.LessonTitle>
            <Styled.LessonType>
              <img src={MSQIcon} alt=""/>
              <span>MCQ</span>
            </Styled.LessonType>
          </Styled.LessonContent>
        </Styled.Lesson>
      </Styled.Panel>);
  }

  function renderPreAssessment() {
    return (
      <Styled.Panel
        showArrow={false}
        key={`assessment__${preAssessment}`}
        header={
          <Styled.ModulePanel>
            <Styled.ModulePanelTitle>Pre-Assessment</Styled.ModulePanelTitle>{" "}
            <Styled.CollapseIcon>
              {assessmentId && assessmentId === preAssessment ? <UpOutlined/> : <DownOutlined/>}
            </Styled.CollapseIcon>
          </Styled.ModulePanel>
        }
      >
        <Styled.Lesson
          $isActive={assessmentId === `${preAssessment}`}
          to={buildPath.assessment(courseId, preAssessment)}
        >
          <>
            <Styled.LessonNumber>1</Styled.LessonNumber>
            {currentProgress?.preAssessment && (
              <Styled.LessonDone>
                <CheckOutlined/>
              </Styled.LessonDone>
            )}
          </>
          <Styled.LessonContent>
            <Styled.LessonTopRow>
              <Styled.LessonTopNumber>Pre-Assessment</Styled.LessonTopNumber>
            </Styled.LessonTopRow>
            <Styled.LessonTitle>{preAssessment?.title}</Styled.LessonTitle>
            <Styled.LessonType>
              <img src={MSQIcon} alt=""/>
              <span>MCQ</span>
            </Styled.LessonType>
          </Styled.LessonContent>
        </Styled.Lesson>
      </Styled.Panel>);
  }

  return (
    <Styled.Collapse
      ghost
      activeKey={expandedTabs}
      onChange={(ids) => {
        setTabExpand(ids);
      }}
    >
      {!!preAssessment && renderPreAssessment()}
      {sortBy(modules, "rank")?.map((module, index) => {
        return (
          <React.Fragment key={`${module?.id}`}>
            {!!module?.lessons?.length && (
              <Styled.Panel
                showArrow={false}
                key={`${module?.id}`}
                header={
                  <Styled.ModulePanel>
                    <Styled.ModulePanelTitle>
                      {module?.title}
                    </Styled.ModulePanelTitle>{" "}
                    <Styled.CollapseIcon>
                      {isPanelExpanded(module?.id) ? (
                        <UpOutlined/>
                      ) : (
                        <DownOutlined/>
                      )}
                    </Styled.CollapseIcon>
                  </Styled.ModulePanel>
                }
              >
                <div>
                  {module.lessons?.map((lesson, i) => (
                    <Styled.Lesson
                      $isDisabled={
                        !isLessonCurrentActive(
                          lessonWithIds,
                          currentProgress,
                          lesson?.id
                        ) &&
                        !isLessonFinished(currentProgress, lesson?.id) &&
                        isLessonDisabled(
                          lesson?.id,
                          lessonId,
                          currentProgress,
                          lessonWithIds
                        )
                      }
                      $isActive={`${lessonId}` === `${lesson?.id}`}
                      to={buildPath.lesson(courseId, module?.id, lesson?.id)}
                      key={lesson?.id}
                      $isDone={isLessonFinished(currentProgress, lesson?.id)}
                    >
                      <>
                        {isLessonFinished(currentProgress, lesson?.id) && (
                          <Styled.LessonDone>
                            <CheckOutlined/>
                          </Styled.LessonDone>
                        )}
                      </>
                      <>
                        {!isLessonFinished(currentProgress, lesson?.id) &&
                          !isLessonCurrentActive(
                            lessonWithIds,
                            currentProgress,
                            lesson?.id
                          ) &&
                          isLessonDisabled(
                            lesson?.id,
                            lessonId,
                            currentProgress,
                            lessonWithIds
                          ) && (
                            <Styled.LessonLocked>
                              <LockOutlined/>
                            </Styled.LessonLocked>
                          )}
                      </>
                      <>
                        {isLessonCurrentActive(
                          lessonWithIds,
                          currentProgress,
                          lesson?.id
                        ) && <Styled.LessonNumber>{i + 1}</Styled.LessonNumber>}
                      </>
                      <Styled.LessonContent>
                        <Styled.LessonTopRow>
                          <Styled.LessonTopNumber>
                            Lesson 0{i + 1}
                          </Styled.LessonTopNumber>
                        </Styled.LessonTopRow>
                        <Styled.LessonTitle>{lesson?.title}</Styled.LessonTitle>
                        <Styled.LessonType>
                          <img src={TYPE_TO_ICON[lesson?.type]} alt=""/>
                          <span>{lesson?.type}</span>
                        </Styled.LessonType>
                      </Styled.LessonContent>
                    </Styled.Lesson>
                  ))}
                </div>
              </Styled.Panel>
            )}
          </React.Fragment>
        );
      })}
      {!!assessment && renderAssessment()}
    </Styled.Collapse>
  );
};

export default CourseAside;
