import React from "react";
import countBy from "lodash/countBy";
import { useDispatch } from "react-redux";

import Styled from "./styled";
import { clearFinalAssessmentAction } from "../../services/finalAssessment/finalAssessment.actions";

const FinalAssessmentResult = ({ result, clearData }) => {
  const countAnswers = countBy(result?.answersDetails, "isCorrect");
  const detailsArr = Object.values(result?.answersDetails);
  const dispatch = useDispatch();

  return (
    <Styled.FailureRow>
      <div>
        <Styled.FailureTitle>Oh no..</Styled.FailureTitle>
        <Styled.FailureDesc>
          Unfortunately you didn't passed the assessment
        </Styled.FailureDesc>
        <Styled.FailureScore>
          🤔 {countAnswers.false} out of {detailsArr?.length} answers wrong
        </Styled.FailureScore>
      </div>
      <Styled.FailureButton onClick={() => clearData()}>
        Retry again
      </Styled.FailureButton>
    </Styled.FailureRow>
  );
};

export default FinalAssessmentResult;
