import styled, { css } from "styled-components";

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 24px;

  video {
    position: absolute;
    background: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ $isDisabled }) =>
      $isDisabled &&
      css`
        pointer-events: none;
      `}
  }
`;

const VideoOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s;
  ${({ isLoaded }) =>
    !isLoaded &&
    css`
      opacity: 1;
    `}
  &:hover {
    opacity: 1;
  }
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Controls = styled.div`
  z-index: 2;
  background: #ffffff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  color: #000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 28px;
  .anticon {
    font-size: 25px;
  }
`;

export default {
  VideoWrapper,
  Controls,
  VideoOverlay,
};
