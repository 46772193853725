import React from "react";

import Styled from "./styled";
import { useSelector } from "react-redux";
import { progressSelector } from "../../../services/progress/progress.selector";

const CourseHeader = ({ course, courseLogo }) => {
  const progress = useSelector(progressSelector);
  const currentProgress = progress?.[course?.id];
  const progressCount = currentProgress?.assessment
    ? currentProgress?.finishedCount + 1
    : currentProgress?.finishedCount;
  const percents = Math.floor(
    (progressCount / (parseInt(currentProgress?.courseLessonsCount) + 1)) * 100
  );
  return (
    <Styled.Header>
      <Styled.HeaderTop>
        <Styled.HeaderLogo>
          <img src={courseLogo} alt="" />
        </Styled.HeaderLogo>
      </Styled.HeaderTop>
      <Styled.HeaderInfo>
        <Styled.HeaderWrapper>
          <Styled.HeaderContent>
            <Styled.HeaderCapture>Course 0{course?.rank}</Styled.HeaderCapture>
            <Styled.HeaderTitle>{course?.title}</Styled.HeaderTitle>
            <p>{course?.shortDescription}</p>
          </Styled.HeaderContent>
          <Styled.HeaderProgressBlock>
            <Styled.HeaderProgressCircle
              type="circle"
              percent={percents}
              trailColor={"#daeee0"}
              strokeColor={"#16a513"}
              strokeWidth={3}
              format={(percent) => (
                <div>
                  <Styled.HeaderProgressPercentage>
                    {percent}%
                  </Styled.HeaderProgressPercentage>
                  <Styled.HeaderProgressName>
                    Progress
                  </Styled.HeaderProgressName>
                </div>
              )}
            />
          </Styled.HeaderProgressBlock>
        </Styled.HeaderWrapper>
      </Styled.HeaderInfo>
    </Styled.Header>
  );
};

export default CourseHeader;
