import React, {useEffect} from "react";

import {history, urlLocations} from "routes/urlLocations";
import Styled from "./styled";
import Logo from "../../assets/img/logo.png";
import SponsorLogo from "../../assets/img/sponsorLogo.png";
import {STEPS} from "./MainLayout.constants";
import {isReplica} from "../../constants";

export default React.memo(({children}) => {
  useEffect(() => {
    const storage = localStorage.getItem("storageTyp");
    const jwt = window[storage]?.getItem("jwt");
    jwt && history.push(urlLocations.home);
  }, []);

  return (
    <div>
      <Styled.Header>
        <Styled.HeaderContainer>
          <Styled.AppLogo src={Logo} alt=""/>
          {isReplica && <Styled.HeaderLogo src={SponsorLogo} alt=""/>}
        </Styled.HeaderContainer>
      </Styled.Header>
      <Styled.MainContainer>
        <Styled.MainInfo>
          <Styled.MainInfoBG/>
          <Styled.MainBox>
            <Styled.MainTitle>{isReplica ? 'Diabetes Management Training Program For Primary healthcare Physicians' :
              'Diabetes Academy'}</Styled.MainTitle>
            <Styled.MainDesc>
              Welcome to our educational online course!<br/>
              Login and start learning your way around the course and proceed with the following steps:
            </Styled.MainDesc>
            <div>
              {STEPS.map((step, index) => {
                return (
                  <Styled.MainStep key={step}>
                    <Styled.MainStepNumber>0{index + 1}</Styled.MainStepNumber>
                    <Styled.MainStepTitle>{step}</Styled.MainStepTitle>
                  </Styled.MainStep>
                );
              })}
            </div>
          </Styled.MainBox>
        </Styled.MainInfo>
        <Styled.MainBlock>
          <Styled.MainContent>{children}</Styled.MainContent>
        </Styled.MainBlock>
      </Styled.MainContainer>
      <Styled.Footer>
        <Styled.FooterContainer>
          ©2020 All Rights Reserved. ADA Diabetes Academy
        </Styled.FooterContainer>
      </Styled.Footer>
    </div>
  );
});
