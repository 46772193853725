import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";

const StyledButton = styled(AntButton)`
  color: #ffffff;
  background: #7a00e6;
  padding: 16px 32px;
  height: initial;
  border-radius: 8px;
  border-color: #7a00e6;

  &:focus {
    color: #ffffff;
    background: #7a00e6;
    padding: 16px 32px;
    height: initial;
    border-radius: 8px;
    border-color: #7a00e6;
  }
  &:active,
  &:hover {
    color: #7a00e6;
    background: #ffffff;
    border-color: #7a00e6;
  }
`;

const Button = (props) => {
  return <StyledButton {...props} />;
};

export default Button;
