import React from "react";
import { useSelector } from "react-redux";

import Styled from "./styled";
import CourseHeader from "./Course.Header/Course.Header";
import {
  courseSelector,
  coursesSelector,
} from "services/courses/courses.selectors";
import CourseAside from "./Course.Aside.jsx/Course.Aside";

const Course = ({ children }) => {
  const course = useSelector(courseSelector);
  const courses = useSelector(coursesSelector);
  const courseLogo = courses?.find((c) => c?.id === course?.id)?.logo?.url;
  const assessment = courses?.find((c) => c?.id === course?.id)?.assessment;
  const preAssessment = courses?.find((c) => c?.id === course?.id)?.preAssessment;

  return (
    <Styled.Page>
      <Styled.Container>
        <CourseHeader courseLogo={courseLogo} course={course} />
        <Styled.Content>
          <Styled.Aside>
            <CourseAside assessment={assessment} modules={course?.modules} preAssessment={preAssessment}/>
          </Styled.Aside>
          <Styled.LessonContent>{children}</Styled.LessonContent>
        </Styled.Content>
      </Styled.Container>
    </Styled.Page>
  );
};

export default Course;
