import FacebookIcon from "assets/img/Facebook.png";
import YouTubeIcon from "assets/img/YouTube.png";
import TwitterIcon from "assets/img/Twitter.png";
import InstagramIcon from "assets/img/Instagram.png";
import { urlLocations } from "routes/urlLocations";

export const SOCIALS = [
  { icon: FacebookIcon, path: "facebook.com" },
  { icon: YouTubeIcon, path: "youtube.com" },
  { icon: TwitterIcon, path: "twitter.com" },
  { icon: InstagramIcon, path: "instagram.com" },
];

export const FOOTER_NAV = [
  {
    name: "About",
    path: urlLocations.homeAbout,
  },
  {
    name: "Courses",
    path: urlLocations.homeCourses,
  },
  {
    name: "Privacy Policy",
    path: "https://www.campus.sanofi/sa/footer-links/privacy-policy",
  },
];
