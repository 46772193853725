import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Styled from "./styled";
import {
  finalAssessmentResultSelector,
  finalAssessmentSelector,
} from "services/finalAssessment/finalAssessment.selectors";
import {
  clearFinalAssessmentAction,
  submitFinalAssessmentAction,
} from "services/finalAssessment/finalAssessment.actions";
import FinalAssessmentResult from "./FinalAssessment.Result";

const FinalAssessment = () => {
  const [answers, setAnswers] = useState({});
  const dispatch = useDispatch();

  const clearData = () => {
    dispatch(clearFinalAssessmentAction());
    setAnswers({});
  };

  const getStatus = (questionID, id, answersDetails) => {
    if (!answersDetails) return null;
    if (
      answersDetails?.isCorrect &&
      `${answersDetails?.correctOptionID}` === `${id}`
    ) {
      return "correctAnswer";
    }
    if (
      !answersDetails?.isCorrect &&
      result?.passed &&
      `${id}` === `${answersDetails?.correctOptionID}`
    )
      return "correctOption";
    if (
      !answersDetails[questionID]?.isCorrect &&
      `${answers[questionID]}` === `${id}`
    )
      return "incorrectAnswer";

    return "hasResult";
  };

  const assessment = useSelector(finalAssessmentSelector);
  const result = useSelector(finalAssessmentResultSelector);

  return (
    <Styled.Page>
      <Styled.Container>
        <Styled.Header>
          <Styled.HeaderTop>
            <Styled.HeaderCapture>Final Assessment</Styled.HeaderCapture>
          </Styled.HeaderTop>
          <Styled.HeaderContent>
            <Styled.HeaderTitle>You are almost there</Styled.HeaderTitle>
            <Styled.HeaderDesc>
              You’re almost there! Please take the following final assessment to gain your certificate.
            </Styled.HeaderDesc>
          </Styled.HeaderContent>
        </Styled.Header>
        <Styled.Wrapper>
          <div>
            {assessment?.questions?.map((question, i) => (
              <Styled.List key={question?.id}>
                <Styled.Question>{question?.title}</Styled.Question>
                <Styled.Options>
                  {question?.options?.map(({ option, id }, index) => (
                    <Styled.Option
                      status={getStatus(
                        question?.id,
                        `${id}`,
                        result?.answersDetails?.[question?.id]
                      )}
                      onClick={() =>
                        setAnswers({
                          ...answers,
                          [question?.id]: `${id}`,
                        })
                      }
                      selected={answers[question?.id] === `${id}`}
                      key={id}
                    >
                      <Styled.CheckBox
                        status={getStatus(
                          question?.id,
                          `${id}`,
                          result?.answersDetails?.[question?.id]
                        )}
                        selected={answers[question?.id] === `${id}`}
                      />
                      <div>
                        <Styled.OptionIndex>
                          {(parseInt(index) + 10).toString(36).toUpperCase()}
                          {". "}
                        </Styled.OptionIndex>
                        {option}
                      </div>
                    </Styled.Option>
                  ))}
                </Styled.Options>
              </Styled.List>
            ))}
          </div>
          <>
            {result ? (
              <FinalAssessmentResult clearData={clearData} result={result} />
            ) : (
              <Styled.Button
                onClick={() => dispatch(submitFinalAssessmentAction(answers))}
              >
                Submit
              </Styled.Button>
            )}
          </>
        </Styled.Wrapper>
      </Styled.Container>
    </Styled.Page>
  );
};

export default FinalAssessment;
