import { call, put, select, takeEvery } from "redux-saga/effects";
import { createMatchSelector, LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import * as coursesApi from "./courses.api";
import * as coursesActions from "./courses.actions";
import * as coursesSelectors from "./courses.selectors";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import { isUrlMatch } from "services/router/router.utils";
import { urlLocations } from "../../routes/urlLocations";
import { assessmentResultSelector } from "./courses.selectors";
import { GET_PROGRESS } from "../progress/progress.actions";

function* clearAssessmentResultOnLocationChange() {
  try {
    const result = yield select(assessmentResultSelector);
    if (!isEmpty(result)) {
      yield put({ type: coursesActions.CLEAR_ASSESSMENT_ANSWERS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCoursesOnLocationChange() {
  try {
    const courses = yield select(coursesSelectors.coursesSelector);
    const storage = localStorage.getItem("storageTyp");
    const token = window[storage]?.jwt;
    if (isEmpty(courses) && token) {
      yield put({ type: coursesActions.GET_COURSES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCourseOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.lessonInfo)) {
      const getMatch = createMatchSelector(urlLocations.lessonInfo);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.courseId");
      yield !!id && put({ type: coursesActions.GET_COURSE, payload: id });
    }
    if (isUrlMatch(payload, urlLocations.assessmentInfo)) {
      const getMatch = createMatchSelector(urlLocations.assessmentInfo);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.courseId");
      yield !!id && put({ type: coursesActions.GET_COURSE, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLessonOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.lessonInfo)) {
      const getMatch = createMatchSelector(urlLocations.lessonInfo);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.lessonId");
      yield !!id && put({ type: coursesActions.GET_LESSON, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAssessmentOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.assessmentInfo)) {
      const getMatch = createMatchSelector(urlLocations.assessmentInfo);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.assessmentId");
      yield !!id && put({ type: coursesActions.GET_ASSESSMENT, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchCourses() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(coursesApi.getCoursesApi);

    yield put({
      type: coursesActions.GET_COURSES_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: coursesActions.GET_COURSES_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchLesson({ payload: id }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(coursesApi.getLessonApi, id);

    yield put({
      type: coursesActions.GET_LESSON_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: coursesActions.GET_LESSON_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchCourse({ payload: id }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(coursesApi.getCourseApi, id);

    yield put({
      type: coursesActions.GET_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: coursesActions.GET_COURSE_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchAssessment({ payload: id }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(coursesApi.getAssessmentApi, id);

    yield put({
      type: coursesActions.GET_ASSESSMENT_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: coursesActions.GET_ASSESSMENT_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitAssessmentAnswers({ payload }) {
  try {
    yield put(startLoaderAction());
    const response = yield call(coursesApi.submitAssessmentAnswersApi, payload);

    yield put({
      type: coursesActions.SUBMIT_ASSESSMENT_ANSWERS_SUCCESS,
      payload: response,
    });
    yield put({
      type: GET_PROGRESS,
    });
  } catch ({ response }) {
    yield put({ type: coursesActions.SUBMIT_ASSESSMENT_ANSWERS_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* coursesSaga() {
  yield takeEvery(LOCATION_CHANGE, clearAssessmentResultOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getCoursesOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getCourseOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getLessonOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getAssessmentOnLocationChange);
  yield takeEvery(
    coursesActions.SUBMIT_ASSESSMENT_ANSWERS,
    submitAssessmentAnswers
  );
  yield takeEvery(coursesActions.GET_COURSES, fetchCourses);
  yield takeEvery(coursesActions.GET_COURSE, fetchCourse);
  yield takeEvery(coursesActions.GET_LESSON, fetchLesson);
  yield takeEvery(coursesActions.GET_ASSESSMENT, fetchAssessment);
}
