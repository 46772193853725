import styled, { css } from "styled-components";
import BG from "assets/img/FinalAssessmentBG.jpg";
import { MEDIA } from "../../constants/media";

const Page = styled.div`
  padding: 40px 0 100px;
`;

const OPTION_STATUS = {
  hasResult: css`
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  `,
  correctAnswer: css`
    background: #f9f7fb;
    border: 1px solid #16a513;
    color: #16a513;
    pointer-events: none;
  `,
  correctOption: css`
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid #16a51380;
    pointer-events: none;
  `,
  incorrectAnswer: css`
    background: #fff7f7;
    border: 1px solid #ff8989;
    pointer-events: none;
    text-decoration-line: line-through;
  `,
};

const CHECKBOX_STATUS = {
  incorrectAnswer: css`
    border-color: #ff8989;
    &:after {
      background-color: #ff8989;
    }
  `,
  correctOption: css`
    border-color: #16a51380;
    &:after {
      display: none;
    }
  `,
  correctAnswer: css`
    border-color: #16a513;
    &:after {
      background-color: #16a513;
    }
  `,
};

const Container = styled.div`
  width: 1360px;
  margin: 0 auto;
  ${MEDIA.lgAndLess} {
    max-width: 90%;
  }
`;

const Header = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 80px;
`;

const HeaderTop = styled.div`
  padding-top: 160px;
  text-align: center;
  background: url(${BG});
  background-size: cover;
`;

const HeaderCapture = styled.div`
  display: inline-block;
  padding: 18px 32px;
  background: #7a00e6;
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  transform: translateY(30px);
  font-weight: 500;
`;

const HeaderContent = styled.div`
  padding: 40px 0 50px;
  text-align: center;
  background: #f4f2f6;
  ${MEDIA.smAndLess} {
    padding: 40px 20px 20px;
  }
`;

const HeaderTitle = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 16px;
  ${MEDIA.smAndLess} {
    font-size: 32px;
    margin-bottom: 32px;
  }
`;

const HeaderDesc = styled.div`
  max-width: 60%;
  margin: 0 auto;
  ${MEDIA.smAndLess} {
    max-width: 100%;
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 65px;
  font-size: 32px;
`;

const Question = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;
`;

const Option = styled.div`
  display: flex;
  flex: 0 0 calc(50% - 12px);
  padding: 15px;
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  ${MEDIA.mdAndLess} {
    flex: 0 0 100%;
  }
  ${({ selected }) =>
    selected &&
    css`
      border-color: #7a00e6;
      background: #f9f7fb;
    `}
  ${({ status }) => {
    return !!status && OPTION_STATUS[status];
  }}
`;

const OptionIndex = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const CheckBox = styled.div`
  position: relative;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  margin-right: 8px;
  margin-top: -2px;
  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid #7a00e6;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        background: #7a00e6;
        border-radius: 50%;
      }
    `}
  ${({ status }) => {
    return !!status && CHECKBOX_STATUS?.[status];
  }}
`;

const Button = styled.div`
  padding: 17px 32px;
  background: #7a00e6;
  border: 1px solid #7a00e6;
  border-radius: 40px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  &:hover {
    background: #ffffff;
    color: #7a00e6;
  }
`;

const Wrapper = styled.div`
  max-width: 65%;
  margin: 0 auto;
  ${MEDIA.smAndLess} {
    max-width: 100%;
  }
`;

const FailureRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 65px;
  align-items: center;
  ${MEDIA.lgAndLess} {
    padding: 20px;
    flex-direction: column;
  }
`;

const FailureTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const FailureDesc = styled.div`
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.8);
`;

const FailureScore = styled.div`
  padding: 10px 24px;
  background: #f4f2f6;
  border-radius: 8px;
  font-size: 18px;
  color: #ff8989;
  font-weight: bold;
  ${MEDIA.lgAndLess} {
    margin-bottom: 20px;
  }
`;

const FailureButton = styled.div`
  font-size: 18px;
  color: #ffffff;
  padding: 17px 32px;
  background: #7a00e6;
  border-radius: 40px;
  border: 1px solid #7a00e6;
  height: fit-content;
  cursor: pointer;
  &:hover {
    color: #7a00e6;
    background: #ffffff;
  }
`;

const List = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  &:last-child {
    border: none;
  }
`;

export default {
  Page,
  Container,
  Header,
  HeaderTop,
  HeaderCapture,
  HeaderContent,
  HeaderTitle,
  HeaderDesc,
  Options,
  Title,
  Question,
  Option,
  OptionIndex,
  CheckBox,
  Button,
  Wrapper,
  FailureRow,
  FailureTitle,
  FailureDesc,
  FailureScore,
  FailureButton,
  List,
};
