import styled from "styled-components";
import { Checkbox as AntCheckbox } from "antd";

const Checkbox = styled(AntCheckbox)`
  &&&.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #7a00e6;
  }

  &&& .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7a00e6;
    border-color: #7a00e6;
  }

  &&& .ant-checkbox-checked::after {
    border-color: #7a00e6;
  }
`;

export default Checkbox;
