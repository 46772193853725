import React, {useEffect} from "react";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";

import {isReplica, STORAGE_TYPE} from "constants/index";
import {history, urlLocations} from "routes/urlLocations";
import Styled from "./styled";
import Logo from "../../assets/img/LogoHeader.png";
import ProfilePlaceholder from "assets/img/profile.png";
import {FOOTER_NAV, SOCIALS} from "./AuthorizedLayout.constants";
import {profileSelector} from "services/profile/profile.selectors";
import {logoutAction} from "services/logout/logout.action";
import {useLocation} from "react-router";
import ProfilePopup from "components/ProfilePopup/ProfilePopup";
import SanofiLOGO from "assets/img/SanofiLOGO.png";
import Acc from "assets/img/acc.jpg";

const AuthorizedLayout = React.memo(({children}) => {
  React.useEffect(() => {
    const storage =
      localStorage?.getItem("storageTyp") || STORAGE_TYPE.SESSION_STORAGE;
    const jwt = window[storage]?.getItem("jwt");
    !jwt && history.push(urlLocations.login);
  }, []);

  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();

  const [isOpen, setOpenStatus] = React.useState(false);

  const scrollTo = (id) => {
    const section = document.querySelector(id);
    section.scrollIntoView({behavior: "smooth", block: "start"});
    // history.push({ hash: "" });
  };

  const {hash} = useLocation();

  useEffect(() => {
    hash && scrollTo(hash);
  }, [hash]);

  return (
    <main>
      <Styled.Header id={"intro"}>
        <Styled.HeaderContainer>
          <Styled.HeaderLogos>
            <Styled.HeaderLogo to={`${urlLocations.home}/#intro`}>
              <img src={Logo} alt=""/>
            </Styled.HeaderLogo>
            <Styled.SponsorLogo>
              <span>
                In <br/>
                Collaboration <br/> with
              </span>
              <img src={profile?.localSponsor?.logo?.url} alt=""/>
            </Styled.SponsorLogo>
          </Styled.HeaderLogos>
          <div>
            <Styled.HeaderNav>
              <Styled.HeaderNavItem to={urlLocations.homeAbout}>
                About
              </Styled.HeaderNavItem>
              <Styled.HeaderNavItem to={urlLocations.homeCourses}>
                Courses
              </Styled.HeaderNavItem>
            </Styled.HeaderNav>
            <Styled.HeaderProfile
              onMouseEnter={() => setOpenStatus(true)}
              onMouseLeave={() => setOpenStatus(false)}
            >
              <Styled.HeaderProfileAvatar>
                <img src={ProfilePlaceholder} alt=""/>
              </Styled.HeaderProfileAvatar>
              <Styled.HeaderProfileName>
                {profile?.firstName} {profile?.lastName}
              </Styled.HeaderProfileName>
              <Styled.HeaderProfileIcon>
                {isOpen ? <UpOutlined/> : <DownOutlined/>}
              </Styled.HeaderProfileIcon>
              {isOpen && (
                <Styled.HeaderMenuWrapper>
                  <Styled.HeaderMenu>
                    <Styled.HeaderMenuEmail>
                      {profile?.email}
                    </Styled.HeaderMenuEmail>
                    <Styled.HeaderMenuLogout
                      onClick={() => dispatch(logoutAction())}
                    >
                      logout
                    </Styled.HeaderMenuLogout>
                  </Styled.HeaderMenu>
                </Styled.HeaderMenuWrapper>
              )}
            </Styled.HeaderProfile>
          </div>
        </Styled.HeaderContainer>
      </Styled.Header>
      {children}
      <Styled.Footer>
        <Styled.FooterContainer>
          <div>
            <Styled.HeaderLogos>
              <Styled.HeaderLogo to={`${urlLocations.home}/#intro`}>
                <img src={Logo} alt=""/>
              </Styled.HeaderLogo>
              <Styled.SponsorLogo>
              <span>
                In <br/>
                Collaboration <br/> with
              </span>
                <img src={profile?.localSponsor?.logo?.url} alt=""/>
              </Styled.SponsorLogo>
            </Styled.HeaderLogos>
            <Styled.FooterCopyright>
              Copyright American Diabetes Association © 2023
            </Styled.FooterCopyright>
          </div>
          {isReplica && <Styled.FooterACC src={Acc}/>}
          <div>
            <Styled.FooterSocials>
              {/*{SOCIALS.map((social) => (*/}
              {/*  <Styled.FooterSocialItem*/}
              {/*    target={"_blank"}*/}
              {/*    key={social.path}*/}
              {/*    href={social.path}*/}
              {/*  >*/}
              {/*    <img src={social.icon} alt=""/>*/}
              {/*  </Styled.FooterSocialItem>*/}
              {/*))}*/}
            </Styled.FooterSocials>

            <Styled.FooterNav>
              {FOOTER_NAV.map((item) => (
                item.path.startsWith('http') ?
                  <Styled.FooterNavItemHREF key={item.path} target={"_blank"} href={item.path}>
                    {item.name}
                  </Styled.FooterNavItemHREF> :
                  <Styled.FooterNavItem key={item.path} to={item.path}>
                    {item.name}
                  </Styled.FooterNavItem>
              ))}
            </Styled.FooterNav>
          </div>
        </Styled.FooterContainer>
      </Styled.Footer>
      {!isReplica && <Styled.Bottom>
        <Styled.BottomContainer>
          <Styled.BottomLogo>
            <img src={SanofiLOGO} alt=""/>
          </Styled.BottomLogo>
          <Styled.BottomText>
            SANOFI, Kingdom of Saudi Arabia, P.O. Box 9874, Jeddah 21423, K.S.A,<br/>
            Tel.: <Styled.BottomLink href="tel:+966126693318">+966-12-669-3318</Styled.BottomLink> Fax: +966-12-663-6191<br/>
            For further Medical Information: please contact: <Styled.BottomLink
            href="tel:+966126693318">+966-12-669-3318</Styled.BottomLink>, Email: <Styled.BottomLink
            href="mailto:ksa.medicalinformation@sanofi.com">ksa.medicalinformation@sanofi.com</Styled.BottomLink><br/>
            To report any Product Technical Complaints, kindly contact: Email: <Styled.BottomLink
            href="mailto:quality.greatergulf@sanofi.com">quality.greatergulf@sanofi.com</Styled.BottomLink><br/>
            In case of any drug related adverse events, please contact:<br/> The National Pharmacovigilance Center
            (NPC):
            Call Center: <Styled.BottomLink
            href="tel:19999">19999</Styled.BottomLink>, E-mail: <Styled.BottomLink
            href="mailto:npc.drug@sfda.gov.sa">npc.drug@sfda.gov.sa</Styled.BottomLink>, Website: <Styled.BottomLink
            href="https://ade.sfda.gov.sa">https://ade.sfda.gov.sa</Styled.BottomLink><br/> And Sanofi
            Pharmacovigilance Department: Phone: <Styled.BottomLink
            href="tel:+966544284797">+966-544-284-797</Styled.BottomLink>, E-mail:
            <Styled.BottomLink
              href="mailto:ksa_pharmacovigilance@sanofi.com">Ksa_pharmacovigilance@sanofi.com</Styled.BottomLink><br/>
            MAT-AE-2100444/V2/Nov 2021
          </Styled.BottomText>
        </Styled.BottomContainer>
      </Styled.Bottom>}
      <ProfilePopup/>
    </main>
  );
});

export default AuthorizedLayout;
