import React from "react";
import Styled from "./styled";
import {urlLocations} from "../../routes/urlLocations";
import RegisterForm from "./Register.Form";

const Register = () => {
  return (
    <Styled.Container>
      <Styled.Title>Creat New Account</Styled.Title>
      <RegisterForm />
      <Styled.ForgotPasswordLinkWrapper>
        <Styled.ForgotPasswordLink to={urlLocations.login}>
          Already have an Account, Login
        </Styled.ForgotPasswordLink>
      </Styled.ForgotPasswordLinkWrapper>

      <Styled.Desc>
        By continuing you agree to our <a href="#">T&Cs.</a> Please also check
        out our <a href="https://www.campus.sanofi/sa/footer-links/privacy-policy">Privacy Policy</a>. We use your data to offer you a
        personalised experience and to better understand and improve our
        services. <a href="">For more information see here.</a>
      </Styled.Desc>
    </Styled.Container>
  );
};

export default Register;
