import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const urlLocations = {
  login: "/",
  home: "/home",
  forgotPassword: "/forgot-password",
  register: "/register",
  homeAbout: "/home#about",
  homeCourses: "/home#courses",
  lesson: "/lesson",
  lessonInfo: "/lesson/:courseId/:moduleId?/:lessonId?",
  assessment: "/assessment",
  assessmentInfo: "/assessment/:courseId/:assessmentId?",
  finalAssessment: "/final-assessment",
  success: "/success",
};

export const buildPath = {
  course: (id) => `${urlLocations.lesson}/${id}`,
  assessment: (courseId, id) => `${urlLocations.assessment}/${courseId}/${id}`,
  lesson: (id, moduleId, lessonId) =>
    `${urlLocations.lesson}/${id}/${moduleId}/${lessonId}`,
};
