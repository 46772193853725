import styled from "styled-components";
import { Link } from "react-router-dom";
import { MEDIA } from "constants/media";

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  ${MEDIA.mdAndLess} {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

const Capture = styled.div`
  text-transform: uppercase;
  color: #7a00e6;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Desc = styled.div`
  margin-bottom: 32px;
`;

const Download = styled.a`
  color: #7a00e6;
  `;
const Button = styled(Link)`
  display: inline-block;
  padding: 17px 32px;
  color: #ffffff;
  background-color: #7a00e6;
  border-radius: 40px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border: 1px solid #7a00e6;
  transition: color, background-color, 0.5s;
  svg {
    transform: rotate(180deg);
    width: 14px;
    height: 7px;
    margin-left: 20px;
    vertical-align: middle;
    path {
      stroke: #ffffff;
      transition: stroke 0.5s;
    }
  }
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    color: #7a00e6;
    background-color: #ffffff;
    text-decoration: none;
    path {
      stroke: #7a00e6;
    }
  }
`;

const LessonContentWrapper = styled.div`
  ${MEDIA.smAndLess} {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    padding: 20px;
    border-radius: 20px 20px 0 0;
  }
`;

const CourseLessonTop = styled.div`
  text-align: right;
  display: none;
  ${MEDIA.smAndLess} {
    display: block;
  }
`;

const CourseLessonContent = styled.div`
  ${MEDIA.smAndLess} {
    width: 100%;
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
`;

const CourseLessonClose = styled(Link)`
  margin-bottom: 20px;
  .anticon {
    font-size: 25px;
    color: black;
  }
`;

const LessonTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  ${MEDIA.mdAndLess} {
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }
`;

export default {
  Title,
  Capture,
  Desc,
  Button,
  Download,
  LessonContentWrapper,
  CourseLessonTop,
  CourseLessonClose,
  CourseLessonContent,
  LessonTitleWrapper,
};
