import instance from "../root.api";

export const getFinalAssessmentApi = () =>
  instance.get(`/final-assessment`).then((response) => ({ response }));

export const submitFinalAssessmentAnswerApi = (answers) => {
  return instance
    .post(`/final-assessment/answer`, { answers })
    .then((response) => ({ response }));
};
