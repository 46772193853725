import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import countBy from "lodash/countBy";

import Styled from "./styled";
import Logo from "assets/img/ADALogoLight.png";
import { finalAssessmentResultSelector } from "services/finalAssessment/finalAssessment.selectors";
import {isReplica} from "../../constants";
import {profileSelector} from "../../services/profile/profile.selectors";
import keys from "lodash/keys";

const SuccessPage = () => {
  const result = useSelector(finalAssessmentResultSelector);
  const profile = useSelector(profileSelector);
  const { true: countAnswers } = countBy(result?.answersDetails, "isCorrect");

  return (
    <Styled.Page>
      <Styled.Container>
        <Styled.Header>
          <Styled.HeaderTop>
            <Styled.HeaderWrapperLogo>
              <Styled.HeaderLogo>
                <img src={profile?.localSponsor?.logoSimple?.url || Logo} alt="" />
              </Styled.HeaderLogo>
              <Styled.SuccessIcon>
                <CheckOutlined />
              </Styled.SuccessIcon>
            </Styled.HeaderWrapperLogo>
          </Styled.HeaderTop>
          <Styled.HeaderContent>
            <Styled.Title>Great job!</Styled.Title>
            <Styled.ScoreWrapper>
              <Styled.Score>
                😎 {countAnswers} out of{" "}
                {keys(result?.answersDetails)?.length} answers correct
              </Styled.Score>
            </Styled.ScoreWrapper>
            <Styled.Desc>
              You passed the course successfully, You will receive ADA {isReplica ? '& SHC' : ''} certificate of completion by e-mail after completing the feedback survey
            </Styled.Desc>
            <br/>
            <Styled.SurveyLink target={'_blank'} href={'https://sanofidigital.iad1.qualtrics.com/jfe/form/SV_9BHbmN9NbLXSwWW?Country=SA&Type=Online&TA=Endocrinology&DA=Diabetes&CN=ADACMEMODULES&Function=md'}>
              Start from here
            </Styled.SurveyLink>
          </Styled.HeaderContent>
        </Styled.Header>
      </Styled.Container>
    </Styled.Page>
  );
};

export default SuccessPage;
