import React, { useEffect, useRef, useState } from "react";
import Styled from "./styled";
import { updateProgressAction } from "services/progress/progress.actions";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { progressSelector } from "services/progress/progress.selector";
import { updateVideoLogsAction } from "services/logs/logs.actions";

const LessonVideo = ({ lesson }) => {
  const material = lesson?.material?.[0];
  const videoRef = useRef(null);
  const [isLoaded, setVideoLoaded] = useState(false);

  const [isVideoPlaying, setVideoStatus] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [refVideo, setVideoRef] = useState(null);
  const [loop, setLoop] = useState(undefined);
  const progress = useSelector(progressSelector);
  const { courseId } = useParams();

  const currentProgress = progress?.[courseId];
  const dispatch = useDispatch();

  const video = videoRef.current;
  const successDuration = refVideo?.duration * 0.9;

  const handleRef = (node) => {
    node && setVideoRef(node);
  };

  const updateProgress = () => {
    setVideoProgress(refVideo?.currentTime);
  };

  useEffect(() => {
    if (!!refVideo && !isLoaded) {
      refVideo.load();
    }
    if (!!refVideo) {
      refVideo.oncanplay = () => {
        setVideoLoaded(true);
      };
      refVideo.onplay = () => {
        setVideoStatus(true);
      };
      refVideo.onpause = () => {
        setVideoStatus(false);
      };
      refVideo.onseeked = () => {
        setVideoProgress(video?.currentTime);
      };
    }
  }, [refVideo, isVideoPlaying, isLoaded, videoProgress]);

  useEffect(() => {
    setVideoLoaded(false);
  }, [lesson])

  useEffect(() => {
    clearInterval(loop);
    if (isVideoPlaying) {
      setLoop(setInterval(updateProgress, 1000));
      return;
    }
    clearInterval(loop);
    return () => {
      clearInterval(loop);
    };
  }, [isVideoPlaying]);

  useEffect(() => {
    if (
      videoProgress &&
      !currentProgress?.finishedLessons?.includes(lesson?.id)
    ) {
      dispatch(
        updateVideoLogsAction({
          lesson: lesson?.id,
          secondsWatched: Math.floor(videoProgress),
        })
      );
    }
    if (
      videoProgress >= successDuration &&
      !currentProgress?.finishedLessons?.includes(lesson?.id)
    ) {
      dispatch(
        updateProgressAction({
          lesson: lesson?.id,
          course: courseId,
        })
      );
    }
  }, [videoProgress]);

  return (
    <Styled.VideoWrapper $isDisabled={!isLoaded}>
      <video
        controls
        ref={handleRef}
        preload={"true"}
        poster={material?.thumbnail?.url}
      >
        <source src={material?.video?.url || material?.videoURL} type={material?.video?.mime || 'video/mp4'} />
      </video>
    </Styled.VideoWrapper>
  );
};

export default LessonVideo;
