import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import {useCountdown} from "../../../hooks/useCountdown";
import Styled from "../styled";

const ExpiredNotice = () => {
  return (
    <div>
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({days, hours, minutes, seconds, isExpired}) => {
  return (
    <Styled.CountDownHolder>
      <Styled.CountDownRemainingText isExpired={isExpired}>Remaining Time: </Styled.CountDownRemainingText>
      <DateTimeDisplay value={days} type={'Days'} isExpired={isExpired}/>
      <DateTimeDisplay value={hours} type={'Hours'} isExpired={isExpired}/>
      <DateTimeDisplay value={minutes} type={'Mins'} isExpired={isExpired}/>
      <DateTimeDisplay value={seconds} type={'Seconds'} isExpired={isExpired}/>
    </Styled.CountDownHolder>
  );
};

const CountdownTimer = ({targetDate}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const isExpired = days + hours + minutes + seconds <= 0

  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      isExpired={isExpired}
    />
  );
};

export default CountdownTimer;
