import React from "react";
import { Field, reduxForm } from "redux-form";
import { Select as AntSelect } from "antd";
import { useSelector } from "react-redux";

import InputField from "components/InputField/InputField";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import Checkbox from "components/Checkbox/Checkbox";
import { appInfoSelector } from "services/appInfo/appInfo.selector";
import Styled from "./styled";
import openNotification from "../../utils/notifications";
import { updateProfileAction } from "../../services/profile/profile.actions";
import {isReplica} from "../../constants";
const { Option } = AntSelect;

const ProfilePopupForm = ({ handleSubmit, ...error }) => {
  const appInfo = useSelector(appInfoSelector);
  return (
    <form onSubmit={handleSubmit}>
      <Styled.FieldWrapper>
        <Field component={Select} name="speciality" placeholder="Speciality">
          {appInfo?.specialities?.map(({ title, id }) => (
            <Option key={id} value={id}>
              {title}
            </Option>
          ))}
        </Field>
      </Styled.FieldWrapper>
      <Styled.FieldWrapper>
        <Field component={InputField} name="city" placeholder="City" />
      </Styled.FieldWrapper>
      <Styled.FieldWrapper>
        <Field
          component={InputField}
          name="placeOfWork"
          placeholder="Place of work"
        />
      </Styled.FieldWrapper>
      <Styled.FieldWrapper>
        <Field
          component={InputField}
          name="phoneNumber"
          placeholder="Phone Number"
        />
      </Styled.FieldWrapper>
      {isReplica && <Styled.FieldWrapper>
        <Field
          component={InputField}
          name="numberSCFHS"
          placeholder="SCFHS Number"
        />
      </Styled.FieldWrapper>}
      <Styled.FieldWrapper>
        <Field component={Checkbox} name="agreement">
          I agree on receiving emails from Sanofi (we will provide you with the
          exact text and a clickable link for the privacy policy)
        </Field>
      </Styled.FieldWrapper>
      <Styled.ButtonRow>
        <Button htmlType={"submit"}>Submit</Button>
      </Styled.ButtonRow>
    </form>
  );
};

export default reduxForm({
  form: "profileForm",
  onSubmit: (value, dispatch) => {
    const valuesArr = Object.values(value);
    const isValid = valuesArr.every((v) => !!v);
    if (isValid) {
      dispatch(
        updateProfileAction({
          ...value,
        })
      );
      return;
    }
    openNotification({
      type: "error",
      message: "Error",
      description: "All fields are required",
    });
  },
})(ProfilePopupForm);
