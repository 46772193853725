import { createAction } from "redux-actions";

export const GET_PROGRESS = "GET_PROGRESS";
export const GET_PROGRESS_SUCCESS = "GET_PROGRESS_SUCCESS";
export const GET_PROGRESS_FAILURE = "GET_PROGRESS_FAILURE";

export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATE_PROGRESS_SUCCESS = "UPDATE_PROGRESS_SUCCESS";
export const UPDATE_PROGRESS_FAILURE = "UPDATE_PROGRESS_FAILURE";

export const updateProgressAction = createAction(UPDATE_PROGRESS);
