import { createAction } from "redux-actions";

export const UPDATE_VIDEO_LOGS = "UPDATE_VIDEO_LOGS";
export const UPDATE_VIDEO_LOGS_SUCCESS = "UPDATE_VIDEO_LOGS_SUCCESS";
export const UPDATE_VIDEO_LOGS_FAILURE = "UPDATE_VIDEO_LOGS_FAILURE";

export const UPDATE_SLIDES_LOGS = "UPDATE_SLIDES_LOGS";
export const UPDATE_SLIDES_LOGS_SUCCESS = "UPDATE_VIDEO_LOGS_SUCCESS";
export const UPDATE_SLIDES_LOGS_FAILURE = "UPDATE_VIDEO_LOGS_FAILURE";

export const GET_SLIDES_LOGS = "GET_SLIDES_LOGS";
export const GET_SLIDES_LOGS_SUCCESS = "GET_SLIDES_LOGS_SUCCESS";
export const GET_SLIDES_LOGS_FAILURE = "GET_SLIDES_LOGS_FAILURE";

export const updateVideoLogsAction = createAction(UPDATE_VIDEO_LOGS);
export const updateSlideLogsAction = createAction(UPDATE_SLIDES_LOGS);
