import * as logsActions from "./logs.actions";
import initialState from "store/initialState";

export default (state = initialState.logs, action) => {
  switch (action.type) {
    case logsActions.GET_SLIDES_LOGS_SUCCESS:
      return {
        ...state,
        slidesLogs: action.payload,
      };
    default:
      return state;
  }
};
