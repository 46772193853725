import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Course from "../Course/Course";
import {
  assessmentResultSelector,
  assessmentSelector,
  coursesSelector,
} from "services/courses/courses.selectors";
import Styled from "./styled";
import {
  clearAssessmentResultAction,
  submitAssessmentAnswersAction,
} from "services/courses/courses.actions";
import AssessmentResult from "./Assessment.Result/Assessment.Result";
import { buildPath } from "routes/urlLocations";
import { CloseOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import AssessmentMCQ from "./Assessment.MCQ";
import AssessmentCross from "./Assessment.Cross";

const renderOptions = ({ question, isPreAssessment, ...props }) => {
  return {
    mcq: <AssessmentMCQ {...props} question={question} isPreAssessment={isPreAssessment}/>,
    cross: <AssessmentCross {...props} question={question} isPreAssessment={isPreAssessment}/>,
  }?.[question?.type];
};

const Assessment = () => {
  const [answers, setAnswers] = useState({});
  const dispatch = useDispatch();
  const { courseId, assessmentId } = useParams();

  const assessment = useSelector(assessmentSelector);
  const isPreAssessment = assessment?.isPreAssessment
  const result = useSelector(assessmentResultSelector);

  window.addEventListener("resize", () => {
    const param = window.innerWidth < 768 && assessmentId ? "hidden" : "auto";
    document.body.style.overflow = param;
  });

  useEffect(() => {
    if (window.innerWidth < 768 && assessmentId) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [assessmentId]);

  const clearData = () => {
    dispatch(clearAssessmentResultAction());
    setAnswers({});
  };

  return (
    <Course>
      <Styled.Content>
        <Styled.ContentWrapper>
          <Styled.Top>
            <Styled.Close to={buildPath.course(courseId)}>
              <CloseOutlined />
            </Styled.Close>
          </Styled.Top>
          <Styled.Scroll>
            <Styled.Title>{assessment?.title}</Styled.Title>
            <div>
              {assessment?.questions?.map((question, i) => (
                <Styled.List key={question?.id}>
                  <Styled.Question>{question?.title}</Styled.Question>
                  <Styled.Options>
                    {renderOptions({ question, isPreAssessment, result, answers, setAnswers })}
                  </Styled.Options>
                </Styled.List>
              ))}
            </div>

            {!!result ? (
              <AssessmentResult
                courseId={courseId}
                clearData={clearData}
                result={result}
                isPreAssessment={isPreAssessment}
              />
            ) : (
              <Styled.Button
                onClick={() =>
                  dispatch(
                    submitAssessmentAnswersAction({
                      id: assessment?.id,
                      answers,
                    })
                  )
                }
              >
                Submit
              </Styled.Button>
            )}
          </Styled.Scroll>
        </Styled.ContentWrapper>
      </Styled.Content>
    </Course>
  );
};

export default Assessment;
